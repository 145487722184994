import React from "react";
import { Audit } from "../../../types/ApiTypes";
import styles from "./AuditListItem.module.css";
import firebase from "firebase/app";
import { ArrowRight, CheckCircle, Loader } from "react-feather";
import { formatCurrency } from "../../../helpers/formatCurrency";
import dayjs from "dayjs";
import classNames from "classnames";
import { Button } from "../../common/Button/Button";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { concatStrings } from "../../../helpers/concatStrings";

export type AuditListItemProps = {
  audit: firebase.firestore.DocumentSnapshot<Audit>;
  last?: boolean;
  showViewDetails?: boolean;
  showAmount?: boolean;
};

export function AuditListItem({
  audit,
  last = false,
  showViewDetails = true,
  showAmount = true,
}: AuditListItemProps) {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const totalRecovered = concatStrings(
    Object.entries(audit.data().totalRecovered)
      .sort(([a], [b]) => (a > b ? 1 : -1))
      .map(([currency, amount]) => `${formatCurrency(amount, currency)}`)
  );

  return (
    <div className={classNames(styles.auditListItem, { [styles.last]: last })}>
      <div
        className={classNames(styles.auditStatus, {
          [styles.done]: audit.data().status === "done",
        })}
      >
        {audit.data().status === "in-progress" && (
          <>
            <Loader className="animate-spin" color="#27245B" />
            <p>In Progress</p>
          </>
        )}
        {audit.data().status === "done" && (
          <>
            <CheckCircle color="#43D365" />
            <p>Completed</p>
          </>
        )}
      </div>
      <div className={styles.date}>
        <p>{dayjs.unix(audit.data().created.seconds).format("MM-DD-YYYY")}</p>
      </div>
      {showAmount && (
        <div className={styles.totalRecovered}>
          <p>{totalRecovered}</p>
        </div>
      )}
      {!showAmount && <p className={styles.totalRecovered}>&nbsp;</p>}
      <div
        className={classNames(styles.viewDetailsButton, [
          {
            [styles.inProgress]:
              audit.data().status !== "done" || !showViewDetails,
          },
        ])}
      >
        <Button
          primary
          onClick={() => history.push(`/audits/${audit.id}`)}
          icon={isMobile}
        >
          <span className="hidden sm:block">View Details</span>
          <ArrowRight className="visible sm:hidden" />
        </Button>
      </div>
    </div>
  );
}
