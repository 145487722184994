import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { User } from "../types/ApiTypes";

export function useCustomers() {
  const [users, loading, error] = useCollection<User>(
    firebase.firestore().collection("users").orderBy("email", "asc")
  );

  return {
    customers: users,
    loading,
    error,
  };
}
