import React from "react";
import styles from "../styles/Login.module.css";
import { useLoginForm } from "../hooks/useLoginForm";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { ErrorMessage } from "../components/common/Messages/ErrorMessage";
import querystring from "querystring";

export default function Login() {
  const history = useHistory();
  const location = useLocation();
  const { redirectTo } = querystring.parse(location.search.substr(1));

  const {
    email,
    setEmail,
    password,
    setPassword,
    error,
    loading,
    formValid,
    login,
  } = useLoginForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    var successful = await login();
    if (successful) history.replace((redirectTo as string) ?? "/");
  };

  return (
    <>
      <Helmet>
        <title>Login - Refund Sniper</title>
      </Helmet>

      <main className={styles.loginContainer}>
        <a className={styles.backButton} href="https://refundsniper.com">
          <ArrowLeft color="#27245B" />
          <span>Return to Site</span>
        </a>

        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <div className={styles.header}>
            <img src="/refund_sniper@2x.png" alt="" />
          </div>

          <div className={styles.content}>
            {error && (
              <div className="mb-4">
                <ErrorMessage message={error.message} />
              </div>
            )}

            <label htmlFor="email" className="sr-only">
              Email
            </label>

            <input
              id="email"
              type="email"
              className={styles.input}
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="password" className="sr-only">
              Password
            </label>

            <input
              id="password"
              type="password"
              className={styles.input}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className={styles.inputHelper}>
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>

            <button
              type="submit"
              className={styles.button}
              disabled={!formValid || loading}
            >
              {loading ? "Logging In" : "Login"}
            </button>
          </div>
        </form>
      </main>
    </>
  );
}
