import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { User } from "../types/ApiTypes";

export function useAccountSettingsForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const currentUser = firebase.auth().currentUser;

    firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snap) => {
        const userData = snap.data() as User;
        setName(userData.name);
        setPhone(userData.phoneNumber);
        setEmail(userData.email);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setFormValid(name !== "" && phone !== "");
  }, [name, phone]);

  const saveChanges = () => {
    setSaving(true);

    const currentUser = firebase.auth().currentUser;

    firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .update({
        name,
        email: email.toLowerCase(),
        phoneNumber: phone,
      })
      .catch(setError)
      .finally(() => setSaving(false));
  };

  return {
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    loading,
    saving,
    error,
    formValid,
    saveChanges,
  };
}
