import firebase from "firebase/app";
import { User } from "../types/ApiTypes";
import { useEffect, useState } from "react";

export function useCustomer(userId: string) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [partiallyCreated, setPartiallyCreated] = useState(false);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const [error, setError] = useState<Error | undefined>();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    setEmailSuccess(false);
    return firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .onSnapshot((snap) => {
        const userData = snap.data() as User;
        if (userData.name) setName(userData.name);
        else setName("");
        if (userData.email) setEmail(userData.email);
        if (userData.phoneNumber) setPhone(userData.phoneNumber);
        setPartiallyCreated(userData.partiallyCreated);
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    setFormValid(email !== "");
  }, [email]);
  const saveChanges = () => {
    setError(undefined);
    setSaving(true);
    return firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        name: name,
        email: email,
        phoneNumber: phone ?? "",
      })
      .catch(setError)
      .finally(() => setSaving(false));
  };

  const resendWelcomeEmail = async () => {
    setSendingEmail(true);
    const resend = firebase.functions().httpsCallable("resendWelcomeEmail");
    setError(undefined);
    setEmailSuccess(false);
    try {
      await resend({
        userId: userId,
        name: name,
        email: email,
      });
      setEmailSuccess(true);
      setTimeout(() => setEmailSuccess(false), 5000);
    } catch (err) {
      setError(err);
      setEmailSuccess(false);
    } finally {
      setSendingEmail(false);
    }
  };

  return {
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    loading,
    saving,
    error,
    formValid,
    saveChanges,
    emailSuccess,
    setEmailSuccess,
    partiallyCreated,
    sendingEmail,
    resendWelcomeEmail,
  };
}
