import React from "react";
import { Toolbar } from "../../common/Toolbar/Toolbar";
import { FormGroup } from "../../common/FormGroup/FormGroup";
import { Button } from "../../common/Button/Button";
import { Modal } from "../../common/Modal/Modal";
import { useChangePasswordForm } from "../../../hooks/useChangePasswordForm";
import { ErrorMessage } from "../../common/Messages/ErrorMessage";

export type ChangePasswordModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

export function ChangePasswordModal({
  isOpen,
  onClose,
}: ChangePasswordModalProps) {
  const {
    oldPassword,
    setOldPassword,
    newPassword1,
    setNewPassword1,
    newPassword2,
    setNewPassword2,
    saving,
    formValid,
    error,
    saveChanges,
  } = useChangePasswordForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const successful = await saveChanges();
    if (successful) onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Toolbar>
        <h1>Change Password</h1>
      </Toolbar>
      <form onSubmit={handleSubmit}>
        <div className="p-4 border-b">
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          )}
          <p className="mb-4">
            To change your password, please provide your current password to
            protect the security of your account.
          </p>
          <FormGroup label="Current Password">
            <input
              type="password"
              placeholder="Enter your current password..."
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup label="New Password">
            <input
              type="password"
              placeholder="Enter your new password..."
              value={newPassword1}
              onChange={(e) => setNewPassword1(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup label="Confirm New Password">
            <input
              type="password"
              placeholder="Confirm your new password..."
              value={newPassword2}
              onChange={(e) => setNewPassword2(e.target.value)}
              required
            />
          </FormGroup>
        </div>
        <div className="p-4 flex">
          <Button
            className="mr-2"
            type="submit"
            disabled={!formValid || saving}
            primary
          >
            {saving ? "Saving..." : "Change Password"}
          </Button>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
