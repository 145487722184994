import React from "react";
import { CheckCircle } from "react-feather";
import styles from "./SuccessMessage.module.css";

export type SuccessMessageProps = {
  message: string;
};

export function SuccessMessage({ message }: SuccessMessageProps) {
  return (
    <div className={styles.successMessage}>
      <CheckCircle color="#FFF" />
      <p>{message}</p>
    </div>
  );
}
