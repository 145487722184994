import { useEffect, useState } from "react";
import firebase from "firebase/app";

export function useCreateCompany() {
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState<Error>();
  const [formValid, setFormValid] = useState(false);
  const [creating, setCreating] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);

  const createCompany = async () => {
    setCreating(true);
    setError(undefined);

    try {
      const createCompanyFunc = firebase
        .functions()
        .httpsCallable("createCompany");

      const result = await createCompanyFunc({
        companyName,
      });

      if (result.data.accepted) {
        setSuccess(true);
        return result.data.uid;
      } else {
        setSuccess(false);
        throw new Error(result.data.reason);
      }
    } catch (err) {
      setError(err);
    } finally {
      setCreating(false);
    }
  };

  useEffect(() => {
    setFormValid(companyName.trim() !== "");
  }, [companyName]);

  return {
    companyName,
    setCompanyName,
    formValid,
    creating,
    error,
    createCompany,
  };
}
