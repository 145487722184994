import { useEffect, useState } from "react";
import firebase from "firebase/app";
import { Company } from "../types/ApiTypes";

export function useCreateUserCompanyAssociation() {
  const [error, setError] = useState<Error>();
  const [formValid, setFormValid] = useState(false);
  const [creating, setCreating] = useState(false);

  const associateUserCompany = async (userId: string, companyId: string) => {
    setCreating(true);
    setError(undefined);

    try {
      const createUserCompanyAssociation = firebase
        .functions()
        .httpsCallable("createUserCompanyAssociation");

      const result = await createUserCompanyAssociation({
        userId: userId,
        companyId: companyId,
      });

      if (!result.data.accepted) {
        throw new Error("Unable to create account.");
      }
    } catch (err) {
      setError(err);
    } finally {
      setCreating(false);
    }
  };

  return {
    formValid,
    creating,
    error,
    associateUserCompany,
  };
}
