import { useEffect, useState } from "react";
import firebase from "firebase/app";

export function useForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [sent, setSent] = useState(false);

  const sendEmail = async () => {
    setError(undefined);
    setLoading(true);

    try {
      const result = await firebase.functions().httpsCallable("sendPassword")({
        email,
      });

      if (!result.data.accepted) {
        throw new Error("That email is not associated with any account.");
      } else {
        setLoading(false);
        setSent(true);
        return true;
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }

    return false;
  };

  useEffect(() => {
    setFormValid(email !== "");
  }, [email]);

  return {
    email,
    setEmail,
    error,
    loading,
    formValid,
    sent,
    sendEmail,
  };
}
