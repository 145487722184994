import classNames from "classnames";
import { Button } from "../common/Button/Button";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

export type SidebarItemProps = {
  page: string;
  title: string;
  subtitle?: string | React.ReactNode;
  href: string;
};

export function SidebarItem({ page, title, subtitle, href }: SidebarItemProps) {
  const location = useLocation();
  const history = useHistory();

  return (
    <Button
      clear
      onClick={() => history.push(href)}
      active={location.pathname === href}
      className="block rounded-none w-full text-left p-2 flex h-14 items-center border-b bg-white outline-none"
    >
      <div
        className={
          "w-10 bg-sniper-purple h-10 border-none rounded mr-2 flex items-center justify-center " +
          (page === "user" ? "bg-sniper-green" : "bg-sniper-purple")
        }
      >
        <p
          className={
            "m-0 font-semibold text-lg text-center text-white " +
            (page === "user" ? "text-sniper-purple" : "text-white")
          }
        >
          {title.substring(0, 2).toUpperCase()}
        </p>
      </div>
      <div className="flex-1">
        <h3 className="font-semibold m-0 text-sniper-purple">{title}</h3>
        {!!subtitle && <p className="m-0 text-subtitle-gray">{subtitle}</p>}
      </div>
    </Button>
  );
}
