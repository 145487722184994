import React from "react";
import styles from "./FormGroup.module.css";
import classNames from "classnames";

export type FormGroupProps = {
  label?: string;
  htmlFor?: string;
  helperText?: string;
  basic?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};

export function FormGroup({
  label,
  htmlFor,
  helperText,
  basic = false,
  children,
}: FormGroupProps) {
  return (
    <div className={classNames(styles.formGroup, { [styles.basic]: basic })}>
      <label htmlFor={htmlFor}>{label}</label>
      {children}
      {helperText && <p className="text-xs mt-2">{helperText}</p>}
    </div>
  );
}
