import React, { useState } from "react";
import { Toolbar } from "../../../common/Toolbar/Toolbar";
import { FormGroup } from "../../../common/FormGroup/FormGroup";
import { Button } from "../../../common/Button/Button";
import { Modal } from "../../../common/Modal/Modal";
import { ErrorMessage } from "../../../common/Messages/ErrorMessage";
import SearchUsers from "./SearchUsers";
import { useCreateUserCompanyAssociation } from "../../../../hooks/useCreateUserCompanyAssociation";

export type AddUserToCompanyModalProps = {
  companyId: string;
  isOpen?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
};

export function AddUserToCompanyModal({
  companyId,
  isOpen,
  onSubmit,
  onClose,
}: AddUserToCompanyModalProps) {
  const { error, creating, associateUserCompany } =
    useCreateUserCompanyAssociation();
  const [selectedUser, setSelectedUser] = useState(undefined);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await associateUserCompany(selectedUser, companyId);

    if (!error) {
      onSubmit();
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} loading={creating}>
      <Toolbar>
        <h1>Add User To Company</h1>
      </Toolbar>
      <form onSubmit={handleSubmit}>
        {error && (
          <div className="p-4">
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          </div>
        )}
        <div className="p-4">
          <FormGroup label="Users">
            <SearchUsers
              companyId={companyId}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </FormGroup>
        </div>

        <div className="flex margin-l-auto space-x-5 p-4 border-t">
          <Button type="submit" disabled={!selectedUser} primary className="">
            {!creating ? "Associate User" : "Associating User..."}
          </Button>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className=""
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
