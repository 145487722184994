import React, { useEffect, useState } from "react";
import { Toolbar } from "../../../common/Toolbar/Toolbar";
import { FormGroup } from "../../../common/FormGroup/FormGroup";
import { Button } from "../../../common/Button/Button";
import { Modal } from "../../../common/Modal/Modal";
import { useCompanies } from "../../../../hooks/useCompanies";
import SearchCompanies from "./SearchCompanies";
import { useCreateUserCompanyAssociation } from "../../../../hooks/useCreateUserCompanyAssociation";
import { useCreateCompany } from "../../../../hooks/useCreateCompany";
import { ErrorMessage } from "../../../common/Messages/ErrorMessage";

export type AddCompanyModalProps = {
  userId?: string;
  isOpen?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
};

export function AddCompanyModal({
  userId,
  isOpen,
  onSubmit,
  onClose,
}: AddCompanyModalProps) {
  const {
    companyName,
    setCompanyName,
    creating: creatingCompany,
    createCompany,
    error,
  } = useCreateCompany();
  const { creating: creatingAssociation, associateUserCompany } =
    useCreateUserCompanyAssociation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const companyID = await createCompany();

    if (companyID) {
      await associateUserCompany(userId, companyID);
      onClose();
      onSubmit();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} loading={(creatingCompany || creatingAssociation)}>
      <Toolbar>
        <h1>Add Company</h1>
      </Toolbar>
      <form onSubmit={handleSubmit} className="p-5">
        {error && (
          <div className="mb-4">
            <ErrorMessage message={error.message} />
          </div>
        )}
        <FormGroup label="Company Name">
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
        </FormGroup>
        <Toolbar />
        <FormGroup>
          <div className="flex space-x-5">
            <Button type="submit" disabled={!companyName} primary className="">
              {!(creatingCompany || creatingAssociation)
                ? "Add Company"
                : "Adding Company..."}
            </Button>
            <Button
              secondary
              onClick={() => {
                onClose();
              }}
              className=""
            >
              Cancel
            </Button>
          </div>
        </FormGroup>
      </form>
    </Modal>
  );
}
