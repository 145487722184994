import React from "react";
import styles from "../styles/Login.module.css";
import { Helmet } from "react-helmet";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import { useForgotPasswordForm } from "../hooks/useForgotPasswordForm";
import classNames from "classnames";
import { ErrorMessage } from "../components/common/Messages/ErrorMessage";
import { FormGroup } from "../components/common/FormGroup/FormGroup";

export default function ForgotPassword() {
  const {
    email,
    setEmail,
    loading: loadingPassword,
    error: passwordError,
    formValid: passwordFormValid,
    sent: passwordSent,
    sendEmail: sendPasswordEmail,
  } = useForgotPasswordForm();

  const handlePasswordFormSubmit = (e) => {
    e.preventDefault();
    sendPasswordEmail();
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Refund Sniper</title>
      </Helmet>
      <main className={styles.loginContainer}>
        <a className={styles.backButton} href="https://refundsniper.com">
          <ArrowLeft color="#27245B" />
          <span>Return to Site</span>
        </a>

        {passwordSent && (
          <div className={classNames(styles.loginFormSuccess)}>
            <img src="/audits.svg" alt="" />
            <h3>Check your email!</h3>
            <p>
              We've sent you an email that you can use to reset your password.
            </p>
          </div>
        )}

        {!passwordSent && (
          <div className={styles.loginForm}>
            <div className={styles.header}>
              <img src="/refund_sniper@2x.png" alt="" />
            </div>

            <div className={styles.content}>
              <p className={styles.lastParagraph}>
                Forgot your password? No worries, it happens to everyone! We’ll
                get you back up and running quickly!
              </p>
            </div>

            <div className={styles.content_break} />

            <form onSubmit={handlePasswordFormSubmit}>
              <div className={styles.content}>
                <h2>I know my email</h2>
                <p>
                  If you know your email, provide it below and we’ll send you a
                  link to reset your password.
                </p>

                {passwordError && (
                  <div className="mb-4">
                    <ErrorMessage message={passwordError.message} />
                  </div>
                )}

                <FormGroup label="Email">
                  <input
                    id="email"
                    type="email"
                    className={styles.input}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>

                <button
                  type="submit"
                  className={styles.button}
                  disabled={!passwordFormValid || loadingPassword}
                >
                  {loadingPassword
                    ? "Sending Recovery Email"
                    : "Send Recovery Email"}
                </button>
              </div>
            </form>

            <div className={styles.content}>
              <h2>I do not know my account email</h2>
              <p className={styles.lastParagraph}>
                If you’re unsure of your account email, please get in touch with
                us and we’ll help you regain access to your account.
              </p>
            </div>
          </div>
        )}

        <div className={styles.formHelper}>
          <Link to={"/login"}>Log in to your account</Link>
        </div>
      </main>
    </>
  );
}
