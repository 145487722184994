import React from "react";
import { Helmet } from "react-helmet";
import { Navbar } from "../components/common/Navbar/Navbar";
import { Container } from "../components/common/Container/Container";

export default function Error404() {
  return (
    <>
      <Helmet>
        <title>404: Not Found - Refund Sniper</title>
      </Helmet>

      <Navbar admin={false} />

      <Container>
        <div className="flex items-center justify-center">
          <h1 className="font-bold">404 | Not Found</h1>
        </div>
      </Container>
    </>
  );
}
