import React, { useEffect, useState } from "react";
import path from "path";
import firebase from "firebase/app";
import dayjs from "dayjs";

export function useImportAuditToCompanyForm(companyId: string) {
  const [auditDate, setAuditDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [nextAuditDate, setNextAuditDate] = useState(
    dayjs().add(3, "months").format("YYYY-MM-DD")
  );
  const [auditFile, setAuditFile] = useState<File | undefined>();
  const [sendNotificationEmail, setSendNotificationEmail] = useState(true);
  const [noReimbursements, setNoReimbursements] = useState(false);
  const [auditFileError, setAuditFileError] = useState<Error | undefined>();
  const [importError, setImportError] = useState<Error | undefined>();
  const [importedAuditId, setImportedAuditId] = useState<string | undefined>();
  const [formValid, setFormValid] = useState(false);
  const [importing, setImporting] = useState(false);

  useEffect(() => {
    setFormValid(
      auditFile !== undefined &&
        auditFileError === undefined &&
        auditDate !== ""
    );
  }, [auditFile, auditFileError, auditDate]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuditFileError(undefined);

    try {
      const file = e.target.files[0];
      const extension = path.extname(file.name);

      if (extension !== ".xlsx" && extension !== ".xls") {
        throw new Error(
          "Selected file must either be a .xlsx or .xls Excel file"
        );
      }

      setAuditFile(file);
    } catch (err) {
      setAuditFileError(err);
    }
  };

  const importUpdate = async (companyName: string) => {
    const val = dayjs(auditDate).format("MM-DD-YYYY");
    try {
      setImportedAuditId(undefined);
      setImportError(undefined);
      setImporting(true);

      try {
        if (noReimbursements) {
          const createNoReimbursementAuditFile = firebase
            .functions()
            .httpsCallable("createNoReimbursementAudit");

          const result = await createNoReimbursementAuditFile({
            fileName: `${companyName}_${val} Final Report.xlsx`,
            companyId: companyId,
          });
          if (!result.data.file[0]) {
            const err = new Error("No Reimbursement File Created");
            setAuditFileError(err);
            throw err;
          }
        } else {
          await firebase
            .storage()
            .ref(`audit-results/${companyId}/${auditFile.name}`)
            .put(auditFile);
        }
      } catch (err) {
        console.log("error", err);
        if (err.code === "storage/unauthorized") {
          setAuditFileError(
            new Error("A file with that name has already been uploaded")
          );
        } else {
          setImportError(err);
        }
        return;
      }

      const importAuditFile = firebase
        .functions()
        .httpsCallable("importAuditToCompany");

      const result = await importAuditFile({
        fileName: auditFile
          ? auditFile.name
          : `${companyName}_${val} Final Report.xlsx`,
        companyId: companyId,
        creationDate: dayjs(auditDate).unix() * 1000,
        nextAuditDate: dayjs(nextAuditDate).unix() * 1000,
        sendNotificationEmail,
      });

      setImportedAuditId(result.data.createdAuditId);
    } catch (err) {
      console.error(err);
      setImportError(err);
    } finally {
      setImporting(false);
    }
  };

  return {
    noReimbursements,
    setNoReimbursements,
    auditDate,
    setAuditDate,
    nextAuditDate,
    setNextAuditDate,
    auditFile,
    sendNotificationEmail,
    setSendNotificationEmail,
    importing,
    formValid,
    auditFileError,
    importError,
    importedAuditId,
    handleFileChange,
    importUpdate,
  };
}
