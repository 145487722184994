import React from "react";
import { Helmet } from "react-helmet";
import { Navbar } from "../components/common/Navbar/Navbar";
import { Container } from "../components/common/Container/Container";
import { useParams, Redirect } from "react-router-dom";

export type AuditDetailsParams = {
  auditId: string;
};

export default function AuditRedirect404() {
  const params = useParams<AuditDetailsParams>();
  return ( <Redirect to={`/login?redirectTo=/audits/${params.auditId}`} />
  );
}
