import React from "react";
import { Helmet } from "react-helmet";
import { Navbar } from "../components/common/Navbar/Navbar";
import { Container } from "../components/common/Container/Container";
import { Content } from "../components/common/Content/Content";
import { Toolbar } from "../components/common/Toolbar/Toolbar";
import { FormGroup } from "../components/common/FormGroup/FormGroup";
import { Button } from "../components/common/Button/Button";
import { useContactForm } from "../hooks/useContactForm";
import { Loader } from "react-feather";
import { SuccessMessage } from "../components/common/Messages/SuccessMessage";
import { ErrorMessage } from "../components/common/Messages/ErrorMessage";

export default function Contact() {
  const {
    name,
    setName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    message,
    setMessage,
    formValid,
    loading,
    error,
    sending,
    sent,
    send,
  } = useContactForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await send();
  };

  return (
    <>
      <Helmet>
        <title>Contact - Refund Sniper</title>
      </Helmet>

      <Navbar admin={false} />

      <Container>
        <Content>
          <Toolbar>
            <h1>Contact Us</h1>
          </Toolbar>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col p-5 md:flex-row">
              <div className="flex-1 p-0 pb-5 md:pb-0 md:p-5 md:pr-0">
                <img src="/refund_sniper_large.png" alt="" className="mb-10" />
                <p className="text-lg font-bold mb-5">
                  support@refundsniper.com
                </p>
                <p className="text-lg font-bold">(888) 764-7372</p>
              </div>
              <div className="flex-1">
                {loading && (
                  <div className="p-5 flex items-center justify-center">
                    <Loader className="animate-spin" />
                  </div>
                )}

                {!loading && sent && (
                  <SuccessMessage
                    message={
                      "Your message has been sent, and we'll be in touch shortly. Thank you!"
                    }
                  />
                )}

                {!loading && error && (
                  <div className="mb-4">
                    <ErrorMessage message="We're sorry, something went wrong while sending your message. Please contact us via email or phone if this issue persists." />
                  </div>
                )}

                {!loading && !sent && (
                  <>
                    <FormGroup label="Name">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup label="Email">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup label="Phone Number">
                      <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup label="How Can We Help?">
                      <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      />
                    </FormGroup>
                    <div className="mt-6 flex justify-end">
                      <Button
                        disabled={!formValid || sending}
                        type="submit"
                        primary
                      >
                        {sending ? "Sending..." : "Send Message"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </Content>
      </Container>
    </>
  );
}
