import { CheckCircle, Circle, Loader } from "react-feather";
import { useCustomers } from "../../../../hooks/useCustomers";
import { useCallback, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useCompanyUserList } from "../../../../hooks/useCompanyUserList";
import _ from "lodash";

export type SearchUsersProps = {
  selectedUser: string;
  companyId?: string;
  setSelectedUser?: (value: string) => void;
};

export default function SearchUsers({
  companyId,
  selectedUser,
  setSelectedUser,
}: SearchUsersProps) {
  const [eligibleUsers, setEligibleUsers] = useState([]);
  const {
    customers,
    loading: customerLoading,
    error: customerError,
  } = useCustomers();
  const { companyUsers, loading: loadingCompanyUsers } =
    useCompanyUserList(companyId);

  const [search, setSearch] = useState("");

  useEffect(() => {
    const allUsers = [];
    const filterUsers = [];

    if (!(customerLoading || loadingCompanyUsers)) {
      customers.docs.map((c) => {
        allUsers.push({ id: c.id, data: c.data() });
      });
      companyUsers.docs.map((c) => {
        filterUsers.push({ id: c.id, data: c.data() });
      });
      const filteredUsers = _.differenceWith(
        allUsers,
        filterUsers,
        (o1, o2) => {
          return o1.id === o2.id;
        }
      );
      setEligibleUsers([...filteredUsers]);
    }
  }, [customerLoading, loadingCompanyUsers]);

  const getFilteredCustomers = useCallback(() => {
    const searchedUsers = [];
    const normalizedSearch = search.toLowerCase().trim();

    if (normalizedSearch.length === 0) {
      return eligibleUsers;
    } else {
      eligibleUsers.filter((c) => {
        const normalizedName = c.data.name.toLowerCase().trim();
        const normalizedEmail = c.data.email.toLowerCase().trim();

        if (
          normalizedName.includes(normalizedSearch) ||
          normalizedEmail.includes(normalizedSearch)
        ) {
          searchedUsers.push({ id: c.id, data: c.data });
        }
      });
      return searchedUsers;
    }
  }, [search, customers, eligibleUsers]);

  return (
    <>
      <div className="bg-white border border-solid border-[#dedede] h-full ">
        <div className="border p-2 border-b flex">
          <input
            type="search"
            className="w-full bg-gray-100 p-2"
            placeholder="Search for users..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <RadioGroup
          className="overflow-y-auto h-80"
          value={selectedUser}
          onChange={setSelectedUser}
        >
          {customerLoading && (
            <div className="flex items-center justify-center p-4">
              <Loader className="animate-spin" />
            </div>
          )}
          {!customerLoading &&
            getFilteredCustomers().map((customer) => (
              <RadioGroup.Option
                key={customer.id}
                value={customer.id}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring ring-inherit border-solid border-[#3b82f6] "
                      : ""
                  }
                                ${
                                  checked
                                    ? "border border-solid border-[#3b82f6] bg-opacity-75 text-sniper-purple"
                                    : "bg-gray"
                                }
                                relative border border-solid border-[#dedede] px-5 py-4 cursor-pointer flex focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center w-full">
                      {checked && (
                        <div className="flex-shrink-0 text-sniper-puple pr-3">
                          <CheckCircle className="w-6 h-6" />
                        </div>
                      )}
                      {!checked && (
                        <div className="flex-shrink-0 text-sniper-puple pr-3">
                          <Circle className="w-6 h-6" />
                        </div>
                      )}
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-sniper-purple" : "text-gray-900"
                            }`}
                          >
                            <p>{customer.data.name}</p>{" "}
                            <p>{customer.data.email}</p>
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
        </RadioGroup>
      </div>
    </>
  );
}
