import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { CompanyMembership } from "../types/ApiTypes";
import { useState } from "react";

export function useCustomerCompanyList(userId?: string) {
  const userRef = firebase.firestore().collection("users").doc(userId);
  const [removeError, setRemoveError] = useState<Error | undefined>();
  const [removing, setRemoving] = useState(false);

  const [userCompanies, loading, error] = useCollection<CompanyMembership>(
    firebase
      .firestore()
      .collectionGroup("memberships")
      .where("user", "==", userRef)
      .orderBy("companyName", "asc")
  );

  const removeMembership = async (companyId: string) => {
    try {
      setRemoving(true);

      const membershipRef = await firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("memberships")
        .doc(userRef.id);

      membershipRef.delete();
    } catch (err) {
      setRemoveError(err);
      return;
    } finally {
      setRemoving(false);
    }
  };

  return {
    userCompanies,
    loading,
    removeError,
    removing,
    removeMembership,
    error,
  };
}
