import React from "react";
import firebase from "firebase/app";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CompanyMembership, User } from "./types/ApiTypes";
import Home from "./pages";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgot-password";
import Account from "./pages/account";
import Audits from "./pages/audits";
import AuditDetails from "./pages/audits/[auditId]";
import Error404 from "./pages/404";
import AuditRedirect404 from "./pages/AuditRedirect404";
import Contact from "./pages/contact";
import UserAdmin from "./pages/admin/user-admin";
import QuickBooks from "./pages/admin/quickbooks";
import AcceptInvite from "./pages/accept-invite";
import ResetPassword from "./pages/reset-password";
import { CurrentUserContextProvider } from "./context/CurrentUserContext";
import CompanyAdmin from "./pages/admin/company-admin";
import { Loader } from "react-feather";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [defaultCompanyId, setDefaultCompanyId] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async () => {
      setLoading(true);

      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const userSnap = await firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get();
        const userData = userSnap.data() as User;

        if (userData) {
          const membershipsSnap = await firebase
            .firestore()
            .collectionGroup("memberships")
            .where(
              "user",
              "==",
              firebase.firestore().collection("users").doc(currentUser.uid)
              //TO DO: Order by first added membership, may need to add created to membership collection docs
            )
            .get();

          const membershipData =
            membershipsSnap.docs[0].data() as CompanyMembership;

          setDefaultCompanyId(membershipData.company.id);
          setIsAdmin(userData.admin);
          setIsLoggedIn(true);
          setLoading(false);
        } else {
          setIsAdmin(false);
          setIsLoggedIn(false);
          setLoading(false);
        }
      } else {
        setIsAdmin(false);
        setIsLoggedIn(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <Loader className="animate-spin" />
      </div>
    );
  }

  if (isLoggedIn) {
    return (
      <CurrentUserContextProvider defaultCompanyId={defaultCompanyId}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/audits" component={Audits} />
            <Route exact path="/audits/:auditId" component={AuditDetails} />

            {isAdmin && (
              <>
                <Route
                  exact
                  path="/admin"
                  render={() => <Redirect to={"/admin/company"} />}
                />
                <Route path="/admin/user" component={UserAdmin} />
                <Route path="/admin/company" component={CompanyAdmin} />
                <Route exact path="/admin/quickbooks" component={QuickBooks} />
              </>
            )}

            <Route component={Error404} />
          </Switch>
        </Router>
      </CurrentUserContextProvider>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />

          <Route exact path="/accept-invite/:token" component={AcceptInvite} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route exact path="/audits/:auditId" component={AuditRedirect404} />

          <Route component={Error404} />
        </Switch>
      </Router>
    );
  }
}
