import React, { useEffect, useState } from "react";
import { Toolbar } from "../../../common/Toolbar/Toolbar";
import { FormGroup } from "../../../common/FormGroup/FormGroup";
import { Button } from "../../../common/Button/Button";
import { Modal } from "../../../common/Modal/Modal";
import { useCompanies } from "../../../../hooks/useCompanies";
import SearchCompanies from "./SearchCompanies";
import { useCreateUserCompanyAssociation } from "../../../../hooks/useCreateUserCompanyAssociation";
import { ErrorMessage } from "../../../common/Messages/ErrorMessage";

export type AddCompanyToUserModalProps = {
  userId?: string;
  isOpen?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
};

export function AddCompanyToUserModal({
  userId,
  isOpen,
  onSubmit,
  onClose,
}: AddCompanyToUserModalProps) {
  const { associateUserCompany, creating, error } =
    useCreateUserCompanyAssociation();

  const [selectedCompany, setSelectedCompany] = useState(undefined);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await associateUserCompany(userId, selectedCompany);

    if (!error) {
      onClose();
      onSubmit();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} loading={creating}>
      <Toolbar>
        <h1>Add Company To User</h1>
      </Toolbar>
      <form onSubmit={handleSubmit}>
        {error && (
          <div className="p-4">
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          </div>
        )}
        <div className="p-4">
          <FormGroup label="Company">
            <SearchCompanies
              userId={userId}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
            />
          </FormGroup>
        </div>

        <div className="p-4 border-t flex space-x-5">
          <Button
            type="submit"
            disabled={!selectedCompany}
            primary
            className=""
          >
            {!creating ? "Associate Company" : "Associating Company..."}
          </Button>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className=""
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
