import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Audit, AuditReportItem } from "../types/ApiTypes";
import { useEffect } from "react";

export function useAuditDetails(auditId: string) {
  const [audit, auditLoading, auditError] = useDocument<Audit>(
    firebase.firestore().collection("audits").doc(auditId)
  );

  const [reportItems, reportItemsLoading, reportItemsError] =
    useCollection<AuditReportItem>(
      firebase
        .firestore()
        .collection("audits")
        .doc(auditId)
        .collection("reportItems")
    );

  return {
    audit,
    auditLoading,
    auditError,
    reportItems,
    reportItemsLoading,
    reportItemsError,
  };
}
