import React, { useState } from "react";
import { FormGroup } from "../../common/FormGroup/FormGroup";
import { Button } from "../../common/Button/Button";
import { useAccountSettingsForm } from "../../../hooks/useAccountSettingsForm";
import { Loader } from "react-feather";
import { ChangePasswordModal } from "../ChangePasswordModal/ChangePasswordModal";
import { ErrorMessage } from "../../common/Messages/ErrorMessage";

export function AccountSettings() {
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    error,
    saving,
    formValid,
    saveChanges,
  } = useAccountSettingsForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await saveChanges();
  };

  if (loading) {
    return (
      <div className="p-5 flex items-center justify-center ">
        <Loader className="animate-spin" />
      </div>
    );
  }

  return (
    <>
      <ChangePasswordModal
        isOpen={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
      />

      <form onSubmit={handleSubmit}>
        {error && (
          <div className="mb-4">
            <ErrorMessage message={error.message} />
          </div>
        )}

        <div className="p-5 border-b">
          <FormGroup label="Contact Name">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>

          <FormGroup label="Contact Number">
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </FormGroup>

          <FormGroup label="Contact Email">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-1 mr-4"
              required
            />
          </FormGroup>

          <FormGroup label="Password">
            <div className="flex">
              <input
                type="password"
                value="secretpassword"
                className="flex-1 mr-4"
                readOnly
              />
              <Button type="button" onClick={() => setChangePasswordOpen(true)}>
                Change Password
              </Button>
            </div>
          </FormGroup>
        </div>
        <div className="p-4 flex justify-end">
          <Button type="submit" disabled={!formValid || saving} primary>
            {saving ? "Saving..." : "Save Changes"}
          </Button>
        </div>
      </form>
    </>
  );
}
