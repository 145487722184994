import firebase from "firebase/app";
import { useContext, useEffect, useState } from "react";
import { QuickbooksInvoice } from "../types/ApiTypes";
import dayjs from "dayjs";
import { CurrentUserContext } from "../context/CurrentUserContext";

export function useInvoices() {
  const [invoices, setInvoices] = useState<QuickbooksInvoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const { activeCompanyId } = useContext(CurrentUserContext);

  const refresh = async () => {
    try {
      const getInvoices = firebase.functions().httpsCallable("getInvoices");
      const result = await getInvoices({ companyId: activeCompanyId });
      const unsortedInvoices = result.data as QuickbooksInvoice[];

      const sortedInvoices = unsortedInvoices.sort((a, b) => {
        const createdA = dayjs(a.MetaData.CreateTime);
        const createdB = dayjs(b.MetaData.CreateTime);
        return createdA.isAfter(createdB) ? -1 : 1;
      });

      setInvoices(sortedInvoices);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return {
    invoices,
    loading,
    error,
  };
}
