import React from "react";
import { AuditList } from "../../audits/AuditList/AuditList";
import { useAudits } from "../../../hooks/useAudits";
import { Loader } from "react-feather";
import { AuditCountdown } from "../../common/AuditCountdown/AuditCountdown";

export function MostRecentAudit() {
  const { audits, loading, error } = useAudits();

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Loader className="animate-spin" />
      </div>
    );
  }

  if (audits.docs.length === 0) {
    return <AuditCountdown />;
  }

  return (
    <AuditList
      audits={[audits.docs[0]]}
      showHeader={false}
      showAmount={false}
    />
  );
}
