import React from "react";
import styles from "./Container.module.css";
import classNames from "classnames";

export type ContainerProps = {
  children?: React.ReactNode | React.ReactNode[];
  admin?: boolean;
};

export function Container({ children, admin }: ContainerProps) {
  return (
    <main className={classNames(styles.container, { [styles.admin]: admin })}>
      {children}
    </main>
  );
}
