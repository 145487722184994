import React from "react";

type EditableFormInputProps = {
  type: string;
  value: string;
  readOnlyValue?: string;
  emptyValue?: string;
  onChange: (newValue: string) => void;
  readOnly: boolean;
  required: boolean;
  className?: string;
};

export function EditableFormInput({
  type,
  value,
  readOnlyValue,
  emptyValue,
  onChange,
  readOnly,
  required,
  className,
}: EditableFormInputProps) {
  if (readOnly) {
    return (
      <div>
        {readOnlyValue && <p>{readOnlyValue}</p>}

        {!readOnlyValue && (
          <p>{value && value.trim().length > 0 ? value : emptyValue}</p>
        )}
      </div>
    );
  }

  return (
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      className={className}
    />
  );
}
