import React from "react";
import { AlertCircle } from "react-feather";
import styles from "./ErrorMessage.module.css";

export type ErrorMessageProps = {
  message: string;
};

export function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <div className={styles.errorMessage}>
      <AlertCircle color="#FFF" />
      <p>{message}</p>
    </div>
  );
}
