import React, { useState } from "react";
import styles from "../styles/Login.module.css";
import { Helmet } from "react-helmet";
import { ArrowLeft } from "react-feather";
import { Link, useHistory, useParams } from "react-router-dom";
import { FormGroup } from "../components/common/FormGroup/FormGroup";
import { useResetPasswordForm } from "../hooks/useResetPasswordForm";
import { Button } from "../components/common/Button/Button";
import { ErrorMessage } from "../components/common/Messages/ErrorMessage";

export type ResetPasswordParams = {
  token: string;
};

export default function ResetPassword() {
  const history = useHistory();
  const { token } = useParams<ResetPasswordParams>();

  const {
    newPassword1,
    setNewPassword1,
    newPassword2,
    setNewPassword2,
    error,
    formValid,
    saving,
    saveChanges,
  } = useResetPasswordForm(token);

  const handleFormSubmit = (e) => {
    console.log("Submitting password reset form...");
    e.preventDefault();
    const successful = saveChanges();
    if (successful) {
      history.replace("/");
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password - Refund Sniper</title>
      </Helmet>
      <main className={styles.loginContainer}>
        <a className={styles.backButton} href="https://refundsniper.com">
          <ArrowLeft color="#27245B" />
          <span>Return to Site</span>
        </a>

        <div className={styles.loginForm}>
          <div className={styles.header}>
            <img src="/refund_sniper@2x.png" alt="" />
          </div>
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          )}

          <div className={styles.content}>
            <form onSubmit={handleFormSubmit}>
              <FormGroup label="New Password">
                <input
                  type="password"
                  placeholder="Enter your new password..."
                  value={newPassword1}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  disabled={!!error}
                  required
                />
              </FormGroup>
              <FormGroup label="Confirm New Password">
                <input
                  type="password"
                  placeholder="Confirm your new password..."
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  disabled={!!error}
                  required
                />
              </FormGroup>
              <p></p>
              <Button
                className="mr-2"
                type="submit"
                disabled={!!error || !formValid || saving}
                primary
              >
                {saving ? "Saving..." : "Change Password"}
              </Button>
            </form>
          </div>
        </div>
        <div className={styles.formHelper}>
          <Link to={"/login"}>Log in to your account</Link>
        </div>
      </main>
    </>
  );
}
