import { useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import dayjs from "dayjs";
import styles from "./AuditCountdown.module.css";
import { Loader } from "react-feather";
import { useDocument } from "react-firebase-hooks/firestore";
import { Company, User } from "../../../types/ApiTypes";
import { Button } from "../Button/Button";
import { useHistory } from "react-router-dom";
import { CurrentUserContext } from "../../../context/CurrentUserContext";

export function AuditCountdown() {
  const { activeCompanyId } = useContext(CurrentUserContext);

  const history = useHistory();

  const [company, loading] = useDocument<Company>(
    firebase.firestore().collection("companies").doc(activeCompanyId)
  );
  const [daysUntilNextAudit, setDaysUntilNextAudit] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (!loading && company.data().nextAudit?.seconds) {
      const nextAudit = dayjs.unix(company.data().nextAudit.seconds);
      setDaysUntilNextAudit(nextAudit.diff(dayjs(), "day"));
    } else {
      setDaysUntilNextAudit(undefined);
    }
  }, [company, loading]);

  if (loading) {
    return (
      <div className={styles.countdown}>
        <div className="p-4 flex items-center justify-center">
          <Loader className="animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.countdown}>
      <div className="flex items-center flex-col justify-center">
        {!!daysUntilNextAudit && daysUntilNextAudit > 0 && (
          <>
            <div>
              {daysUntilNextAudit
                .toString()
                .split("")
                .map((char, index) => (
                  <span className={styles.number} key={index}>
                    {char}
                  </span>
                ))}
            </div>
            <h3>
              {daysUntilNextAudit === 1 ? "Day" : "Days"} until your next audit
            </h3>
          </>
        )}

        {!!daysUntilNextAudit && daysUntilNextAudit <= 0 && (
          <>
            <img src="/audits.svg" alt="" />
            <h3>We're hard at work</h3>
          </>
        )}

        {!daysUntilNextAudit && (
          <>
            <img src="/audits.svg" alt="" />
            <h3>Reach out to schedule your next audit</h3>
          </>
        )}
      </div>

      {!!daysUntilNextAudit && daysUntilNextAudit > 0 && (
        <div>
          <p>
            When we're ready to get started on your next audit, you'll receive
            an email notifying you!
          </p>
        </div>
      )}

      {!!daysUntilNextAudit && daysUntilNextAudit <= 0 && (
        <div>
          <p>
            Time to sit back and relax, our snipers are getting to work on your
            account.
          </p>
        </div>
      )}

      {!daysUntilNextAudit && (
        <div>
          <p className="mb-4">
            Please let us know when you'd like us to begin your next audit.
          </p>
          <Button onClick={() => history.push("/contact")} small>
            Get in Touch
          </Button>
        </div>
      )}
    </div>
  );
}
