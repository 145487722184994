import { useEffect, useState } from "react";
import firebase from "firebase/app";

export function useChangePasswordForm() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    setFormValid(
      oldPassword !== "" &&
        newPassword1 !== "" &&
        newPassword2 !== "" &&
        newPassword1 === newPassword2
    );
  }, [oldPassword, newPassword1, newPassword2]);

  const saveChanges = async () => {
    setError(undefined);
    setSaving(true);

    try {
      const result = await firebase
        .functions()
        .httpsCallable("updateUserPassword")({
        old_password: oldPassword,
        new_password: newPassword1,
      });

      if (!result.data.accepted) {
        throw new Error("That password is incorrect.");
      } else {
        setSaving(false);
        setOldPassword("");
        setNewPassword1("");
        setNewPassword2("");
        return true;
      }
    } catch (e) {
      setError(e);
    } finally {
      setSaving(false);
    }

    return false;
  };

  return {
    oldPassword,
    setOldPassword,
    newPassword1,
    setNewPassword1,
    newPassword2,
    setNewPassword2,
    saving,
    error,
    formValid,
    saveChanges,
  };
}
