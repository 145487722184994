import React from "react";
import classNames from "classnames";
import styles from "./Content.module.css";

export type ContentProps = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
};

export function Content({ children, className }: ContentProps) {
  return (
    <div className={classNames(styles.content, className)}>{children}</div>
  );
}
