import React, { useEffect, useState } from "react";
import { Toolbar } from "../../../common/Toolbar/Toolbar";
import { FormGroup } from "../../../common/FormGroup/FormGroup";
import { Button } from "../../../common/Button/Button";
import { Modal } from "../../../common/Modal/Modal";
import { ErrorMessage } from "../../../common/Messages/ErrorMessage";
import { useCreateUserForm } from "../../../../hooks/useCreateUserForm";

export type AddUserModalProps = {
  companyId: string;
  isOpen?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
};

export function AddUserModal({
  companyId,
  isOpen,
  onSubmit,
  onClose,
}: AddUserModalProps) {
  const {
    sendWelcomeEmail,
    setSendWelcomeEmail,
    email,
    setEmail,
    name,
    setName,
    formValid,
    creating: creatingUser,
    createdUserId,
    error,
    success,
    createUser,
    createUserAndAssociateCompany,
  } = useCreateUserForm();

  useEffect(() => {
    if (success && !error) {
      onSubmit();
      onClose();
    }
  }, [success, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createUserAndAssociateCompany(companyId);
    if (success && !error) {
      onSubmit();
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} loading={creatingUser}>
      <Toolbar>
        <h1>Add User</h1>
      </Toolbar>
      <form onSubmit={handleSubmit}>
        <div className="p-5">
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          )}
          <FormGroup label="User Name">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>

          <FormGroup label="Email Address">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormGroup>
        </div>
        <div className="border-b">
          <label className="p-5">
            <input
              type="checkbox"
              className="mb-5 mr-2"
              checked={sendWelcomeEmail}
              onChange={(e) => setSendWelcomeEmail(e.target.checked)}
            />
            Send welcome email
          </label>
        </div>
        <FormGroup>
          <div className="flex space-x-5 p-5">
            <Button type="submit" disabled={!formValid || creatingUser} primary>
              {!creatingUser ? "Add User" : "Adding User..."}
            </Button>
            <Button
              secondary
              onClick={() => {
                onClose();
              }}
              className=""
            >
              Cancel
            </Button>
          </div>
        </FormGroup>
      </form>
    </Modal>
  );
}
