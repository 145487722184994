import { useEffect, useState } from "react";
import firebase from "firebase/app";

export function useResetPasswordForm(token) {
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    setFormValid(
      newPassword1 !== "" &&
        newPassword2 !== "" &&
        newPassword1 === newPassword2
    );
  }, [newPassword1, newPassword2]);

  useEffect(() => {
    (async () => {
      const result = await firebase
        .functions()
        .httpsCallable("validatePasswordResetToken")({
        token: token,
      });
      if (result.data.accepted == false) {
        setError(
          new Error(
            "Your password reset token has expired, please request another through the forget password page."
          )
        );
        setFormValid(false);
        setSaving(false);
        console.log("Password reset token is not valid.");
      } else {
        console.log("Password reset token is valid.");
      }
    })();
  }, [token]);

  const saveChanges = async () => {
    setError(undefined);
    setSaving(true);

    try {
      const result = await firebase
        .functions()
        .httpsCallable("updateUserPasswordWithResetToken")({
        token: token,
        new_password: newPassword1,
      });

      if (!result.data.accepted) {
        throw new Error("Invalid password reset request.");
      } else {
        setSaving(false);
        setNewPassword1("");
        setNewPassword2("");
        return true;
      }
    } catch (e) {
      setError(e);
    } finally {
      setSaving(false);
    }

    return false;
  };

  return {
    newPassword1,
    setNewPassword1,
    newPassword2,
    setNewPassword2,
    saving,
    error,
    formValid,
    saveChanges,
  };
}
