import React, { PropsWithChildren } from "react";
import styles from "./AuditDetailCard.module.css";
import classNames from "classnames";

export type AuditDetailCardProps = PropsWithChildren<{
  centered?: boolean;
  className?: string;
}>;

export function AuditDetailCard({
  centered = false,
  className,
  children,
}: AuditDetailCardProps) {
  return (
    <div
      className={classNames(styles.auditDetailCard, className, {
        [styles.centered]: centered,
      })}
    >
      {children}
    </div>
  );
}
