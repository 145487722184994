import { AuditReportItem } from "../../../types/ApiTypes";
import { useBlockLayout, useTable } from "react-table";
import firebase from "firebase/app";
import dayjs from "dayjs";
import styles from "./AuditReportTable.module.css";
import { ExternalLink } from "react-feather";

type AuditReportItemSnapshot =
  firebase.firestore.QueryDocumentSnapshot<AuditReportItem>;

export type AuditReportTableProps = {
  data: AuditReportItemSnapshot[];
};

export function AuditReportTable({ data }: AuditReportTableProps) {
  const getReimburseIdLink = (reportItem: AuditReportItem) => {
    switch (reportItem.currency) {
      case "USD":
      case "CAD":
      case "MXN":
        return `https://sellercentral.amazon.com/reportcentral/REIMBURSEMENTS/0/%7B%22filters%22:%5B%22%22,%22%22,%22${reportItem.reimbursementId}%22,%22%22%5D,%22pageOffset%22:1,%22searchDays%22:365%7D`;
      default:
        return `https://sellercentral.amazon.co.uk/reportcentral/REIMBURSEMENTS/0/%7B%22filters%22:%5B%22%22,%22%22,%22${reportItem.reimbursementId}%22,%22%22%5D,%22pageOffset%22:1,%22searchDays%22:365%7D`;
    }
  };

  const getCaseIdLink = (reportItem: AuditReportItem) => {
    let regionalBaseUrl;

    switch (reportItem.currency) {
      case "USD":
      case "CAD":
      case "MXN":
        regionalBaseUrl =
          "https://sellercentral.amazon.com/cu/case-dashboard/view-case?caseID=";
        break;
      default:
        regionalBaseUrl =
          "https://sellercentral.amazon.co.uk/cu/case-dashboard/view-case?caseID=";
        break;
    }

    return regionalBaseUrl + reportItem.caseId;
  };

  const defaultColumn = {
    width: 100,
  };

  const columns = [
    {
      Header: "Approval Date",
      accessor: (r: AuditReportItemSnapshot) =>
        r.data().approvalDate.toDate(),
      Cell: ({ value }) => value.toLocaleDateString('en-US', {timeZone: 'UTC'}),
    },
    {
      Header: "Reimburse ID",
      accessor: (r: AuditReportItemSnapshot) => r.data().reimbursementId,
      Cell: ({ value, row }) => (
        <a
          href={getReimburseIdLink(row.original.data())}
          target="_blank"
          className="flex items-center"
        >
          {value} <ExternalLink size={18} className="ml-2" />
        </a>
      ),
      width: 150,
    },
    {
      Header: "Case ID",
      accessor: (r: AuditReportItemSnapshot) => r.data().caseId,
      Cell: ({ value, row }) =>
        value ? (
          <a
            href={getCaseIdLink(row.original.data())}
            target="_blank"
            className="flex items-center"
          >
            {value} <ExternalLink size={18} className="ml-2" />
          </a>
        ) : (
          "Not Available"
        ),
      width: 150,
    },
    {
      Header: "Reason",
      accessor: (r: AuditReportItemSnapshot) =>
        r.data().reason.replaceAll("_", " "),
      width: 140,
    },
    {
      Header: "SKU",
      accessor: (r: AuditReportItemSnapshot) => {
        const sku = r.data().sku;
        if (typeof sku !== "string") {
          return "N/A"; // Edge case handling for potentially invalid values. (1BY-79)
        }
        return r.data().sku;
      },
      width: 120,
    },
    {
      Header: "ASIN",
      accessor: (r: AuditReportItemSnapshot) => r.data().asin,
    },
    {
      Header: "Currency",
      accessor: (r: AuditReportItemSnapshot) => r.data().currency,
    },
    {
      Header: "Amount Per Unit",
      accessor: (r: AuditReportItemSnapshot) => r.data().amountPerUnit,
      Cell: ({ value, row }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: row.original.data().currency,
        }).format(value / 100),
    },
    {
      Header: "Amount Total",
      accessor: (r: AuditReportItemSnapshot) => r.data().amountTotal,
      Cell: ({ value, row }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: row.original.data().currency,
        }).format(value / 100),
    },
    {
      Header: "QTY Reimb Cash",
      accessor: (r: AuditReportItemSnapshot) => r.data().quantityReimburseCash,
    },
    {
      Header: "QTY Reimb Inventory",
      accessor: (r: AuditReportItemSnapshot) =>
        r.data().quantityReimburseInventory,
    },
    {
      Header: "QTY Reimb Total",
      accessor: (r: AuditReportItemSnapshot) => r.data().quantityReimburseTotal,
    },
    {
      Header: "Inventory Reimb Total",
      accessor: (r: AuditReportItemSnapshot) =>
        r.data().inventoryReimburseTotal,
      Cell: ({ value, row }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: row.original.data().currency,
        }).format(value / 100),
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        defaultColumn,
        columns,
        data,
      },
      useBlockLayout
    );

  return (
    <div className={styles.auditReportTable}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
