import React, { useEffect, useState } from "react";
import { Toolbar } from "../../../common/Toolbar/Toolbar";
import { FormGroup } from "../../../common/FormGroup/FormGroup";
import { Button } from "../../../common/Button/Button";
import { Modal } from "../../../common/Modal/Modal";
import { useCompanies } from "../../../../hooks/useCompanies";
import { ErrorMessage } from "../../../common/Messages/ErrorMessage";
import { useCreateUserForm } from "../../../../hooks/useCreateUserForm";
import { useCreateCompany } from "../../../../hooks/useCreateCompany";
import { useHistory } from "react-router-dom";

import { useCreateUserCompanyAssociation } from "../../../../hooks/useCreateUserCompanyAssociation";
import { Loader } from "react-feather";

export type AddBothUserCompanyModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

export function AddBothUserCompanyModal({
  isOpen,
  onClose,
}: AddBothUserCompanyModalProps) {
  const {
    email,
    setEmail,
    sendWelcomeEmail,
    setSendWelcomeEmail,
    name,
    setName,
    formValid,
    error,
    createUser,
    creating: creatingUser,
  } = useCreateUserForm();

  const {
    companyName,
    setCompanyName,
    creating: creatingCompany,
    createCompany,
  } = useCreateCompany();

  const {
    associateUserCompany,
    error: associationError,
    creating: associating,
  } = useCreateUserCompanyAssociation();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = await createUser();
    const compID = await createCompany();

    if (compID) {
      await associateUserCompany(userId, compID);
      if (!associationError) {
        history.push(`/admin/company/${compID}`);
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} loading={creatingUser || creatingCompany || associating}>
        <Toolbar>
          <h1>Add User And Company</h1>
        </Toolbar>
        <form onSubmit={handleSubmit} className="text-left">
          <div className="p-5">
            {error && (
              <div className="mb-4">
                <ErrorMessage message={error.message} />
              </div>
            )}
            {associationError && (
              <div className="mb-4">
                <ErrorMessage message={error.message} />
              </div>
            )}

            <FormGroup label="User Name">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

            <FormGroup label="Email Address">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>

            <FormGroup label="Company">
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </FormGroup>
          </div>
          <div className="border-b">
            <label className="p-5">
              <input
                type="checkbox"
                className="mb-5 mr-2"
                checked={sendWelcomeEmail}
                onChange={(e) => setSendWelcomeEmail(e.target.checked)}
              />
              Send welcome email
            </label>
          </div>
          <div className="flex space-x-5 p-5 ">
            <Button
              type="submit"
              disabled={
                !formValid || creatingUser || creatingCompany || associating
              }
              primary
              className=""
            >
              {!(creatingUser || creatingCompany || associating)
                ? "Add User And Company"
                : "Adding User And Company..."}
            </Button>
            <Button
              secondary
              onClick={() => {
                onClose();
              }}
              className=""
            >
              Cancel
            </Button>
          </div>
        </form>
    </Modal>
  );
}
