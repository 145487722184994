import { useHistory, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styles from "../styles/Login.module.css";
import { ErrorMessage } from "../components/common/Messages/ErrorMessage";
import React from "react";
import { useAcceptInviteForm } from "../hooks/useAcceptInviteForm";
import { FormGroup } from "../components/common/FormGroup/FormGroup";
import querystring from "querystring";

export type AcceptInviteParams = {
  token: string;
};

export default function AcceptInvite() {
  const history = useHistory();
  const { token } = useParams<AcceptInviteParams>();
  const location = useLocation();
  const { redirectTo } = querystring.parse(location.search.substr(1));

  const {
    name,
    setName,
    email,
    phoneNumber,
    setPhoneNumber,
    password,
    setPassword,
    formValid,
    loading,
    error,
    register,
    tokenExpired,
  } = useAcceptInviteForm(token);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const successful = await register();

    if (successful) {
      setTimeout(() => {
        history.replace((redirectTo as string) ?? "/");
      }, 1000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Account - Refund Sniper</title>
      </Helmet>
      <main className={styles.loginContainer}>
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          )}
          <div className={styles.header}>
            <img src="/refund_sniper@2x.png" alt="" />
          </div>

          <div className={styles.content}>
            {error && (
              <div className="mb-4">
                <ErrorMessage message={error.message} />
              </div>
            )}

            {tokenExpired && (
              <div>
                <ErrorMessage message="We're sorry, this invitation link has expired and is longer valid. Please check your email for our latest communication or contact us if you're still having issues." />
              </div>
            )}

            {!tokenExpired && (
              <>
                <FormGroup label="Primary Contact Name">
                  <input
                    id="primaryContactName"
                    type="text"
                    className={styles.input}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>

                <FormGroup
                  label="Email"
                  helperText="You can change your email from the settings page once you finish setting up your account."
                >
                  <input
                    id="email"
                    type="email"
                    className={styles.input}
                    value={email}
                    disabled
                  />
                </FormGroup>

                <FormGroup label="Phone Number">
                  <input
                    id="phone"
                    type="tel"
                    className={styles.input}
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormGroup>

                <FormGroup label="Password">
                  <input
                    id="password"
                    type="password"
                    className={styles.input}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>

                <button
                  type="submit"
                  className={styles.button}
                  disabled={!formValid || loading}
                >
                  {loading ? "Creating Account" : "Create Account"}
                </button>
              </>
            )}
          </div>
        </form>
      </main>
    </>
  );
}
