import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Company } from "../types/ApiTypes";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export function useCompany(companyId: string) {
  const [name, setName] = useState("");
  const [nextAudit, setNextAudit] = useState(dayjs().format("YYYY-MM-DD"));
  const [quickbooksId, setQuickbooksId] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    return firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .onSnapshot((snap) => {
        const companyData = snap.data() as Company;
        setName(companyData.name);
        setNextAudit(
          companyData.nextAudit
            ? dayjs.unix(companyData.nextAudit.seconds).format("YYYY-MM-DD")
            : undefined
        );
        setQuickbooksId(companyData.quickbooksCustomerId);
        setLoading(false);
      });
  }, [companyId]);

  useEffect(() => {
    setFormValid(name !== "");
  }, [name, nextAudit, quickbooksId]);

  const saveChanges = () => {
    setSaving(true);
    return firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .update({
        name: name,
        nextAudit: firebase.firestore.Timestamp.fromMillis(
          dayjs(nextAudit).unix() * 1000
        ),
        quickbooksCustomerId: quickbooksId ? quickbooksId : "",
      })
      .catch(setError)
      .finally(() => setSaving(false));
  };

  return {
    name,
    setName,
    nextAudit,
    setNextAudit,
    quickbooksId,
    setQuickbooksId,
    loading,
    saving,
    error,
    formValid,
    saveChanges,
  };
}
