import { useEffect, useState } from "react";
import firebase from "firebase/app";
import { useCreateUserCompanyAssociation } from "../hooks/useCreateUserCompanyAssociation";

export function useCreateUserForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [error, setError] = useState<Error>();
  const [formValid, setFormValid] = useState(false);
  const [creating, setCreating] = useState(false);
  const [createdUserId, setCreatedUserId] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);
  const { associateUserCompany, creating: creatingCompany } =
    useCreateUserCompanyAssociation();

  const createUserAndAssociateCompany = async (companyId: string) => {
    setCreating(true);
    setError(undefined);
    setCreatedUserId(undefined);

    const doesUserExist = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get();

    try {
      if (doesUserExist.docs.length != 0) {
        throw new Error("A user with this email already exists.");
      }
      const createUserFunc = firebase.functions().httpsCallable("createUser");
      const result = await createUserFunc({
        name,
        email,
        sendWelcomeEmail,
      });
      if (!result.data.accepted) {
        throw new Error("Unable to create account.");
      }
      setCreatedUserId(result.data.uid);

      try {
        await associateUserCompany(result.data.uid, companyId);
        setSuccess(true);
      } catch (err) {
        setError(err);
      } finally {
        setCreating(false);
      }
      return result.data.uid;
    } catch (err) {
      setError(err);
    } finally {
      setCreating(false);
    }
  };

  const createUser = async () => {
    setCreating(true);
    setError(undefined);
    setCreatedUserId(undefined);

    const doesUserExist = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get();

    try {
      if (doesUserExist.docs.length != 0) {
        setError(new Error("A user with this email already exists."));
        return doesUserExist.docs[0].id;
      }
      const createUserFunc = firebase.functions().httpsCallable("createUser");
      const result = await createUserFunc({
        name,
        email,
        sendWelcomeEmail,
      });
      if (!result.data.accepted) {
        throw new Error("Unable to create account.");
      }

      setSuccess(true);
      setCreatedUserId(result.data.uid);
      return result.data.uid;
    } catch (err) {
      setError(err);
    } finally {
      setCreating(false);
    }
  };

  useEffect(() => {
    setFormValid(email.trim() !== "");
  }, [email]);

  return {
    name,
    setName,
    email,
    setEmail,
    sendWelcomeEmail,
    setSendWelcomeEmail,
    formValid,
    creating,
    createdUserId,
    error,
    success,
    createUser,
    createUserAndAssociateCompany,
  };
}
