import React from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { Button } from "../common/Button/Button";
import { Trash, ArrowRight, Download } from "react-feather";
import ConfirmPopover from "../common/ConfirmPopover/ConfirmPopover";
import { Popover } from "@headlessui/react";

export type ListItemProps = {
  page: string;
  assetId: string;
  email?: string;
  name: string;
  subtitle?: string;
  onRemove: (companyId: string) => void;
  removing?: boolean;
  // onChange: (contact: CompanyContact) => void;
  readOnly?: boolean;
};

export function ListItem({
  page,
  email,
  assetId,
  name,
  subtitle,
  onRemove,
  removing,
  readOnly = false,
}: ListItemProps) {
  const history = useHistory();

  const downloadAuditResults = async () => {
    //Get The Audit
    const audit = await firebase
      .firestore()
      .collection("audits")
      .doc(assetId)
      .get();

    const fileName = audit.data().fileName;

    const file = firebase
      .storage()
      .ref("audit-results")
      .child(audit.data().owner.id)
      .child(fileName);

    const downloadUrl = await file.getDownloadURL();

    window.open(downloadUrl);
  };

  return (
    <div className={"p-2 flex items-center justify-between"}>
      <div className="flex items-center">
        {page != "audit" && (
          <div
            className={
              " w-10 bg-gray-600 h-10 border-none rounded mr-2 flex items-center justify-center " +
              (page === "user" ? "bg-sniper-green" : "bg-sniper-purple")
            }
          >
            <p
              className={
                "m-0 font-semibold text-lg text-center " +
                (page === "user" ? "text-sniper-purple" : "text-white")
              }
            >
              {name!.substring(0, 2).toUpperCase()}
            </p>
          </div>
        )}

        <div className="flex-1">
          <h3 className="text-sniper-purple  m-0">{name!}</h3>
          {email && <p className="m-0 text-subtitle-gray">{email}</p>}
          {!!subtitle && <p className="m-0 text-subtitle-gray">{subtitle}</p>}
        </div>
      </div>
      <div className="flex space-x-1.5 ">
        {page == "audits" && (
          <Button onClick={downloadAuditResults} icon xsmall secondary>
            <Download color="#27245B" size={22} />
          </Button>
        )}
        {!readOnly && (
          <Button className="h-8 w-8" secondary>
            <div className="px-4">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button>
                      <Trash className="mt-1" color="#27245B" size={22} />
                    </Popover.Button>
                    <ConfirmPopover
                      removing={removing}
                      onRemove={onRemove}
                      assetId={assetId}
                    />
                  </>
                )}
              </Popover>
            </div>
          </Button>
        )}
        <Button
          onClick={() =>
            history.push(
              page == "audits"
                ? `/${page}/${assetId}`
                : `/admin/${page}/${assetId}`
            )
          }
          icon
          xsmall
          secondary
        >
          <ArrowRight color="#27245B" size={22} />
        </Button>
      </div>
    </div>
  );
}
