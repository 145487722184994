import firebase from "firebase/app";
import { useCallback, useEffect, useState } from "react";
import { decodeInviteToken } from "../helpers/inviteToken";
import { useHistory, useLocation } from "react-router-dom";
import querystring from "querystring";

export function useAcceptInviteForm(inviteToken: string) {
  const token = decodeInviteToken(inviteToken);
  const history = useHistory();
  const location = useLocation();
  const { redirectTo } = querystring.parse(location.search.substr(1));

  const [name, setName] = useState(token.name);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [tokenExpired, setTokenExpired] = useState(false);

  const register = async () => {
    setLoading(true);

    try {
      // NOTE: It is unnecessary to check whether they have already registered,
      // for if they have, the temporary password will be invalid.

      const result = await firebase.functions().httpsCallable("registerUser")({
        email: token.email,
        oldPassword: token.temporaryPassword,
        newPassword: password,
        name: name,
        phoneNumber: phoneNumber,
      });

      if (!result.data.accepted) {
        throw new Error("This invitation has expired.");
      }

      const loginResult = await firebase.functions().httpsCallable("login")({
        email: token.email,
        password: password,
      });

      if (!loginResult.data.accepted) {
        throw new Error("There has been an internal error, please contact us.");
      }

      await firebase.auth().signInWithCustomToken(loginResult.data.token);
      history.replace((redirectTo as string) ?? "/");
      return true;
    } catch (err) {
      setError(err);
      setLoading(false);
    }

    return false;
  };

  useEffect(() => {
    setFormValid(name !== "" && phoneNumber !== "" && password !== "");
  }, [name, phoneNumber, password]);

  const checkExistingAuth = useCallback(async () => {
    if (firebase.auth().currentUser) {
      history.replace((redirectTo as string) ?? "/");
      return;
    }

    try {
      const loginResult = await firebase.functions().httpsCallable("login")({
        email: token.email,
        password: token.temporaryPassword,
      });

      if (!loginResult.data.accepted) {
        throw new Error("Invalid invite token.");
      }

      // Just to be safe we will sign out if they view this page.
      firebase.auth().signOut();

      setTokenExpired(false);
    } catch (err) {
      // there was a login error, which means the token is expired, so show an error
      setTokenExpired(true);
      console.log("Error: ", err.message);
    }
  }, [history]);

  useEffect(() => {
    checkExistingAuth();
  }, []);

  return {
    tokenExpired,
    name,
    setName,
    email: token.email,
    phoneNumber,
    setPhoneNumber,
    password,
    setPassword,
    formValid,
    loading,
    error,
    register,
  };
}
