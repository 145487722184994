import React from "react";
import styles from "./Modal.module.css";
import { Loader } from "react-feather";
import classNames from "classnames";

export type ModalProps = {
  children?: React.ReactNode | React.ReactNode[];
  fade?: boolean;
  isOpen?: boolean;
  loading?: boolean;
  onClose?: () => void;
};

export function Modal({ isOpen, onClose, loading, children }: ModalProps) {
  return (
    <>
      <div
        className={classNames(styles.modalWrapper, { [styles.open]: isOpen })}
      >
        {isOpen && (
          <a className={styles.scrim} onClick={onClose}>
            <span className="sr-only">Close Modal</span>
          </a>
        )}
        {isOpen && loading ? (
          <div className={styles.modal}>
            <div className="relative">
              <div className="flex items-center justify-center bg-gray-800 opacity-50 absolute w-full h-full" />
              <div className="absolute w-full h-full flex items-center justify-center ">
                <Loader className="animate-spin" />
              </div>
              {children}
            </div>
          </div>
        ) : (
          <div className={styles.modal}>{isOpen && children}</div>
        )}
      </div>
    </>
  );
}
