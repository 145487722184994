import React, { useEffect, useState } from "react";
import { useAudits } from "../../../../hooks/useAudits";
import { ListItem } from "../../ListItem";
import firebase from "firebase/app";
import { Button } from "../../../common/Button/Button";
import dayjs from "dayjs";
import { concatStrings } from "../../../../helpers/concatStrings";
import { formatCurrency } from "../../../../helpers/formatCurrency";
import { Audit } from "../../../../types/ApiTypes";
import ImportAuditModal from "../../../audits/ImportAudit/ImportAuditModal";
import { ErrorMessage } from "../../../common/Messages/ErrorMessage";
import { CheckCircle, Loader } from "react-feather";
import { Transition } from "@headlessui/react";

export type CompanyAuditListProps = {
  companyName: string;
  companyId: string;
  readOnly?: boolean;
};

export function CompanyAuditList({
  companyName,
  companyId,
  readOnly = false,
}: CompanyAuditListProps) {
  const { audits, loading, removeAudit, error } = useAudits(companyId);
  const [importAuditModalOpen, setImportAuditModalOpen] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  function showSavedChangesToast() {
    setChangesSaved(true);
    setTimeout(() => setChangesSaved(false), 5000);
  }

  const formatTotalRecovered = (
    audit: firebase.firestore.DocumentSnapshot<Audit>
  ) => {
    return concatStrings(
      Object.entries(audit.data().totalRecovered)
        .sort(([a], [b]) => (a > b ? 1 : -1))
        .map(([currency, amount]) => `${formatCurrency(amount, currency)}`)
    );
  };

  return (
    <>
      {importAuditModalOpen && (
        <ImportAuditModal
          companyName={companyName}
          companyId={companyId}
          isOpen={true}
          onImported={() => showSavedChangesToast()}
          onClose={() => setImportAuditModalOpen(false)}
        />
      )}
      <div className="flex flex-col border rounded relative">
        <div className="flex-1 max-h-60 overflow-y-auto divide-y">
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          )}
          {loading && (
            <div className="flex items-center justify-center p-20">
              <Loader className="animate-spin" />
            </div>
          )}
          {!loading && audits?.docs.length === 0 && (
            <div className="p-5">No Audits</div>
          )}
          {!loading &&
            audits?.docs.length > 0 &&
            audits?.docs.map((c) => (
              <ListItem
                page="audits"
                key={c.id}
                assetId={c.id}
                name={dayjs
                  .unix(c.data().created.seconds)
                  .format("MMMM DD, YYYY")
                  .toString()}
                subtitle={`${formatTotalRecovered(c)} Recovered`}
                onRemove={removeAudit}
                readOnly={readOnly}
              />
            ))}
        </div>

        <div className="pl-2 p-2 flex border-t items-center justify-between  bg-gray-50">
          <div className="flex items-center space-x-2">
            <Button
              className="text-gray"
              onClick={() => {
                setImportAuditModalOpen(true);
              }}
              icon
              xsmall
              secondary
            >
              Import Audit
            </Button>
          </div>
          <Transition
            show={changesSaved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              style={{ height: 32 }}
              className="flex items-center space-x-2 px-2 bg-green-100 ring-2 ring-green-200 rounded text-green-800"
            >
              <CheckCircle />
              <p className="font-medium text-sm">Audit imported!</p>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
