import { useEffect, useState } from "react";
import { User } from "../types/ApiTypes";
import firebase from "firebase/app";

export function useContactForm() {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const send = async () => {
    // firebase.functions().useEmulator("localhost", 5001);

    setError(undefined);
    setSending(true);
    setSent(false);

    try {
      const sendContactForm = firebase
        .functions()
        .httpsCallable("sendContactForm");

      await sendContactForm({
        name,
        email,
        phoneNumber,
        message,
      });

      setSent(true);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((snap) => {
        const user = snap.data() as User;
        setName(user.name);
        setEmail(firebase.auth().currentUser.email);
        setPhoneNumber(user.phoneNumber);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setFormValid(message !== "");
  }, [name, email, phoneNumber, message]);

  return {
    loading,
    name,
    setName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    message,
    setMessage,
    formValid,
    error,
    sending,
    sent,
    send,
  };
}
