import React, { useEffect, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { UserCompanyList } from "../../../components/admin/user/UserCompanyList";
import { Button } from "../../../components/common/Button/Button";
import { FormGroup } from "../../../components/common/FormGroup/FormGroup";
import { ErrorMessage } from "../../../components/common/Messages/ErrorMessage";
import { useCustomer } from "../../../hooks/useCustomer";
import { CheckCircle, User } from "react-feather";
import { EditableFormInput } from "../../../components/common/EditableFormInput/EditableFormInput";
import { Transition } from "@headlessui/react";
import { SuccessMessage } from "../../../components/common/Messages/SuccessMessage";
export type UserDetailsRouteParams = {
  userId: string;
};

export function UserDetails() {
  const { userId } = useParams<UserDetailsRouteParams>();

  const {
    formValid,
    saving,
    loading: userLoading,
    name,
    setName,
    phone,
    setPhone,
    error,
    email,
    setEmail,
    saveChanges,
    partiallyCreated,
    setEmailSuccess,
    emailSuccess,
    sendingEmail,
    resendWelcomeEmail,
  } = useCustomer(userId);

  const [editing, setEditing] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  function showSavedChangesToast() {
    setChangesSaved(true);
    setTimeout(() => setChangesSaved(false), 5000);
  }

  useEffect(() => {
    window.onbeforeunload = editing ? () => true : undefined;
  }, [editing]);

  return (
    <>
      <Prompt
        when={editing}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <div className="flex p-5">
        <div className="flex-col">
          <div className="bg-sniper-green w-28  h-28 border-none rounded mr-2 flex items-center justify-center">
            <p className="m-0 font-semibold text-5xl text-center text-white text-sniper-purple">
              {name.substring(0, 2).toUpperCase()}
            </p>
          </div>
          <div className="flex space-x-1 justify-center mr-2 mt-2 text-subtitle-gray">
            <User />
            <span>User</span>
          </div>
        </div>
        <div className="flex flex-col pl-5 w-6/12">
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          )}
          {emailSuccess && (
            <div className="mb-4">
              <SuccessMessage message="Welcome email resent!" />
            </div>
          )}
          <FormGroup label="User Name">
            <EditableFormInput
              type="text"
              value={name}
              onChange={setName}
              readOnly={!editing}
              emptyValue="No User Name Specified"
              required
            />
          </FormGroup>

          <FormGroup label="Email Address">
            <div className="flex justify-between">
              <EditableFormInput
                type="email"
                value={email}
                onChange={setEmail}
                className="flex-1 mr-4"
                readOnly={!editing}
                required
              />
              {partiallyCreated && (
                <Button
                  onClick={() => resendWelcomeEmail()}
                  className="h-2 "
                  secondary
                >
                  {sendingEmail
                    ? "Resending Welcome Email..."
                    : "Resend Welcome Email"}
                </Button>
              )}
            </div>
          </FormGroup>
          <FormGroup label="Phone Number">
            <EditableFormInput
              type="tel"
              value={phone}
              emptyValue="No Phone Number Specified"
              onChange={setPhone}
              readOnly={!editing}
              required
            />
          </FormGroup>
          <FormGroup label="Companies" />
          <UserCompanyList userId={userId} readOnly={!editing} />
        </div>

        <div className="flex h-10 items-center space-x-2 ml-auto">
          <Transition
            show={changesSaved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              style={{ height: 43 }}
              className="flex items-center space-x-2 px-4 bg-green-100 ring-2 ring-green-200 rounded text-green-800"
            >
              <CheckCircle />
              <p className="font-medium text-sm">
                Your changes have been saved!
              </p>
            </div>
          </Transition>
          {editing && (
            <>
              <Button type="button" onClick={() => setEditing(false)}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!formValid || saving}
                primary
                onClick={async () => {
                  await saveChanges();
                  setEditing(false);
                  showSavedChangesToast();
                }}
              >
                {!saving ? "Save Changes" : "Saving..."}
              </Button>
            </>
          )}
          {!editing && (
            <>
              <Button type="button" onClick={() => setEditing(true)}>
                Edit User
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
