import React, { useEffect, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { Button } from "../../../components/common/Button/Button";
import { FormGroup } from "../../../components/common/FormGroup/FormGroup";
import { useCompany } from "../../../hooks/useCompany";
import { ErrorMessage } from "../../../components/common/Messages/ErrorMessage";
import { CompanyUserList } from "../../../components/admin/company/CompanyForms/CompanyUserList";
import { CompanyAuditList } from "../../../components/admin/company/CompanyForms/CompanyAuditList";
import styles from "./company.module.css";
import { EditableFormInput } from "../../../components/common/EditableFormInput/EditableFormInput";
import { CheckCircle } from "react-feather";
import { Transition } from "@headlessui/react";
import dayjs from "dayjs";

export type CompanyDetailsRouteParams = {
  companyId: string;
};

export function CompanyDetails() {
  const { companyId } = useParams<CompanyDetailsRouteParams>();

  const {
    name,
    setName,
    nextAudit,
    setNextAudit,
    quickbooksId,
    setQuickbooksId,
    loading: companyLoading,
    formValid,
    saving,
    error,
    saveChanges,
  } = useCompany(companyId);

  const [editing, setEditing] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  function showSavedChangesToast() {
    setChangesSaved(true);
    setTimeout(() => setChangesSaved(false), 5000);
  }

  useEffect(() => {
    window.onbeforeunload = editing ? () => true : undefined;
  }, [editing]);

  return (
    <>
      <Prompt
        when={editing}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      <div className="flex p-5">
        <div className="flex-col">
          <div className="bg-sniper-purple w-28  h-28 border-none rounded mr-2 flex items-center justify-center">
            <p className="m-0 font-semibold text-5xl text-center text-white">
              {name.substring(0, 2).toUpperCase()}
            </p>
          </div>
          <div className="flex space-x-1 justify-center mr-2 mt-2 text-subtitle-gray">
            <img className={styles.grayImage} src="/company.svg" alt="" />
            <span>Company</span>
          </div>
        </div>
        <div className="pl-5 w-6/12 flex flex-col">
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error.message} />
            </div>
          )}
          <FormGroup label="Company Name">
            <EditableFormInput
              type="text"
              value={name}
              onChange={setName}
              readOnly={!editing}
              required
            />
          </FormGroup>

          <FormGroup label="Next Audit">
            <EditableFormInput
              type="date"
              value={nextAudit}
              readOnlyValue={nextAudit && dayjs(nextAudit).format("MM/DD/YYYY")}
              emptyValue={"No Audit Scheduled"}
              onChange={setNextAudit}
              className="flex-1 mr-4"
              readOnly={!editing}
              required
            />
          </FormGroup>

          <FormGroup label="QuickBooks ID">
            <EditableFormInput
              type="text"
              value={quickbooksId}
              emptyValue="No Quickbooks ID Specified"
              onChange={setQuickbooksId}
              readOnly={!editing}
              required
            />
          </FormGroup>

          <div className={styles.formLabel}>Users</div>
          <CompanyUserList companyId={companyId} readOnly={!editing} />
          <div className={styles.formLabel}>Audits</div>
          <CompanyAuditList
            companyName={name}
            companyId={companyId}
            readOnly={!editing}
          />
        </div>

        <div className="flex space-x-2 ml-auto">
          <Transition
            show={changesSaved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              style={{ height: 43 }}
              className="flex items-center space-x-2 px-4 bg-green-100 ring-2 ring-green-200 rounded text-green-800"
            >
              <CheckCircle />
              <p className="font-medium text-sm">
                Your changes have been saved!
              </p>
            </div>
          </Transition>
          {editing && (
            <>
              <Button type="button" onClick={() => setEditing(false)}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!formValid || saving}
                primary
                onClick={async () => {
                  await saveChanges();
                  setEditing(false);
                  showSavedChangesToast();
                }}
              >
                {!saving ? "Save Changes" : "Saving..."}
              </Button>
            </>
          )}
          {!editing && (
            <>
              <Button type="button" onClick={() => setEditing(true)}>
                Edit Company
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
