import React from "react";
import styles from "./AuditDetailCards.module.css";
import { AuditDetailCard } from "../AuditDetailCard/AuditDetailCard";
import firebase from "firebase/app";
import { Audit } from "../../../types/ApiTypes";
import { formatCurrency } from "../../../helpers/formatCurrency";

export type AuditDetailCardsProps = {
  audit: firebase.firestore.DocumentSnapshot<Audit>;
};

export function AuditDetailCards({ audit }: AuditDetailCardsProps) {
  return (
    <div className={styles.auditDetailCards}>
      <AuditDetailCard centered>
        <h1>{audit.data().totalCases.toString()}</h1>
        <p>Total Cases</p>
      </AuditDetailCard>

      <AuditDetailCard className="p-3">
        <p className="mb-4">Total in Payments</p>
        {Object.entries(audit.data().totalInPayments)
          .sort(([a], [b]) => (a > b ? 1 : -1))
          .map(([currency, amount]) => (
            <div
              key={currency}
              className="flex items-center justify-between py-2 border-b"
            >
              <p className="m-0">{currency}</p>
              <p className="m-0">{formatCurrency(amount, currency)}</p>
            </div>
          ))}
      </AuditDetailCard>

      <AuditDetailCard className="p-3">
        <p className="mb-4">Total in Inventory</p>
        {Object.entries(audit.data().totalInInventory)
          .sort(([a], [b]) => (a > b ? 1 : -1))
          .map(([currency, amount]) => (
            <div
              key={currency}
              className="flex items-center justify-between py-2 border-b"
            >
              <p className="m-0">{currency}</p>
              <p className="m-0">{formatCurrency(amount, currency)}</p>
            </div>
          ))}
      </AuditDetailCard>

      <AuditDetailCard className="p-3">
        <p className="mb-4">Total Recovered</p>
        {Object.entries(audit.data().totalRecovered)
          .sort(([a], [b]) => (a > b ? 1 : -1))
          .map(([currency, amount]) => (
            <div
              key={currency}
              className="flex items-center justify-between py-2 border-b"
            >
              <p className="m-0">{currency}</p>
              <p className="m-0">{formatCurrency(amount, currency)}</p>
            </div>
          ))}
      </AuditDetailCard>
    </div>
  );
}
