import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useAuditDetails } from "../../hooks/useAuditDetails";
import { Navbar } from "../../components/common/Navbar/Navbar";
import { Container } from "../../components/common/Container/Container";
import { Content } from "../../components/common/Content/Content";
import { Toolbar } from "../../components/common/Toolbar/Toolbar";
import { AuditList } from "../../components/audits/AuditList/AuditList";
import { AuditDetailCards } from "../../components/audits/AuditDetailCards/AuditDetailCards";
import { ArrowLeft, Download, HelpCircle, Loader } from "react-feather";
import { Link } from "react-router-dom";
import { AuditReportTable } from "../../components/audits/AuditReportTable/AuditReportTable";
import styles from "../../styles/AuditDetails.module.css";
import firebase from "firebase/app";
import { Button } from "../../components/common/Button/Button";

export type AuditDetailsParams = {
  auditId: string;
};

export default function AuditDetails() {
  const params = useParams<AuditDetailsParams>();

  const {
    audit,
    auditLoading,
    auditError,
    reportItems,
    reportItemsLoading,
    reportItemsError,
  } = useAuditDetails(params.auditId);

  const downloadAuditResults = async () => {
    const fileName = audit.data().fileName;

    const file = firebase
      .storage()
      .ref("audit-results")
      .child(audit.data().owner.id)
      .child(fileName);

    const downloadUrl = await file.getDownloadURL();

    window.open(downloadUrl);
  };

  return (
    <>
      <Helmet>
        <title>Audit Results - Refund Sniper</title>
      </Helmet>

      <Navbar admin={false} />

      <Container>
        <Content className="mb-5">
          <Toolbar hasButtons>
            <Link to="/audits">
              <ArrowLeft color="#27245B" />
            </Link>

            {!auditLoading && <h1>Audit Results</h1>}

            <Button onClick={downloadAuditResults} small primary>
              <Download color="#5BF67F" className="mr-2" size={20} />
              Download Results
            </Button>
          </Toolbar>
          {auditLoading && (
            <div className="p-5 flex items-center justify-center">
              <Loader className="animate-spin" />
            </div>
          )}
          {!auditLoading && (
            <>
              <div className="p-5">
                <AuditList audits={[audit]} showViewDetails={false} />
              </div>
              <div className="p-5 pt-0">
                <AuditDetailCards audit={audit} />
              </div>
            </>
          )}
        </Content>

        <Content className="p-5">
          {reportItemsLoading && (
            <div className="p-5 flex items-center justify-center">
              <Loader className="animate-spin" />
            </div>
          )}
          <div className={styles.reportTableWrapper}>
            {!reportItemsLoading && reportItems.docs.length > 0 && (
              <AuditReportTable
                data={reportItems.docs.sort((a, b) => {
                  if (!a.data().sortOrder) return 1;
                  else if (a.data().sortOrder - b.data().sortOrder < 0)
                    return -1;
                })}
              />
            )}

            {!reportItemsLoading && reportItems.docs.length === 0 && (
              <div className="p-5 flex items-center justify-center">
                <p className="m-0 text-sm text-gray-600">
                  No report items to show
                </p>
              </div>
            )}
          </div>
        </Content>
      </Container>
    </>
  );
}
