import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Company } from "../types/ApiTypes";

export function useCompanies() {
  const [companies, loading, error] = useCollection<Company>(
    firebase.firestore().collection("companies").orderBy("name", "asc")
  );

  return {
    companies,
    loading,
    error,
  };
}
