import { Navbar } from "../components/common/Navbar/Navbar";
import { Content } from "../components/common/Content/Content";
import { Toolbar } from "../components/common/Toolbar/Toolbar";
import styles from "../styles/Home.module.css";
import { Helmet } from "react-helmet";
import { Container } from "../components/common/Container/Container";
import { Link } from "react-router-dom";
import { AuditCountdown } from "../components/common/AuditCountdown/AuditCountdown";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Refund Sniper</title>
      </Helmet>
      <Navbar admin={false} />
      <Container>
        <Content>
          <Toolbar>
            <img src="/refund_sniper@2x.png" alt="" className="my-4" />
          </Toolbar>
          <div className={styles.nextAudit}>
            <AuditCountdown />
          </div>
          <div className={styles.cards}>
            <Link to={"/account"} className={styles.card}>
              <img src="/account.svg" alt="" />
              <h3>Account Status</h3>
              <p>See your most recent audit and account information.</p>
            </Link>
            <Link to={"/audits"} className={styles.card}>
              <img src="/audits.svg" alt="" />
              <h3>Audit Results</h3>
              <p>View your account’s audit history.</p>
            </Link>
            <Link to={"/contact"} className={styles.card}>
              <img src="/contact.svg" alt="" />
              <h3>Contact Us</h3>
              <p>Have a question? Get in touch with our team.</p>
            </Link>
          </div>
        </Content>
      </Container>
    </>
  );
}
