import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Company, CompanyMembership } from "../types/ApiTypes";
import { useEffect, useState } from "react";

export function useCompanyUserList(companyId?: string) {
  const [removeError, setRemoveError] = useState<Error | undefined>();
  const [removing, setRemoving] = useState(false);

  const companyRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  const [companyUsers, loading, error] = useCollection<CompanyMembership>(
    firebase
      .firestore()
      .collectionGroup("memberships")
      .where("company", "==", companyRef)
      .orderBy("userEmail", "asc")
  );

  console.log("error", error);
  const removeMembership = async (userId: string) => {
    try {
      setRemoving(true);

      const membershipRef = await firebase
        .firestore()
        .collection("companies")
        .doc(companyRef.id)
        .collection("memberships")
        .doc(userId);

      membershipRef.delete();
    } catch (err) {
      setRemoveError(err);
      return;
    } finally {
      setRemoving(false);
    }
  };

  return {
    companyUsers,
    loading,
    removeMembership,
    removing,
    error,
  };
}
