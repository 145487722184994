export function decodeInviteToken(inviteToken: string) {
  const decodedToken = JSON.parse(Buffer.from(inviteToken, 'base64').toString());

  const uid = decodedToken.uid;
  const companyName = decodedToken.companyName;
  const email = decodedToken.email;
  const temporaryPassword = decodedToken.temporaryPassword;

  return { uid, name: companyName, email, temporaryPassword };
}
