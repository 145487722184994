import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Audit } from "../types/ApiTypes";
import { useContext } from "react";
import { CurrentUserContext } from "../context/CurrentUserContext";

export function useAudits(companyId?: string) {
  const { activeCompanyId } = useContext(CurrentUserContext);

  const activeCompanyRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId ? companyId : activeCompanyId);

  const [audits, loading, error] = useCollection<Audit>(
    firebase
      .firestore()
      .collection("audits")
      .where("owner", "==", activeCompanyRef)
      .orderBy("created", "desc")
  );

  const removeAudit = async (auditId: string) => {
    const auditRef = await firebase
      .firestore()
      .collection("audits")
      .doc(auditId);
    auditRef.delete();
  };

  return {
    audits,
    removeAudit,
    loading,
    error,
  };
}
