import React, { Fragment, useState } from "react";
import { useImportAuditToCompanyForm } from "../../../hooks/useImportAuditToCompanyForm";
import { Button } from "../../common/Button/Button";
import { ErrorMessage } from "../../common/Messages/ErrorMessage";
import { SuccessMessage } from "../../common/Messages/SuccessMessage";
import { Toolbar } from "../../common/Toolbar/Toolbar";
import { Modal } from "../../common/Modal/Modal";

export type ImportAuditModalProps = {
  companyName: string;
  companyId?: string;
  isOpen?: boolean;
  onImported?: () => void;
  onClose?: () => void;
};
export default function ImportAuditModal({
  companyName,
  companyId,
  isOpen,
  onImported,
  onClose,
}: ImportAuditModalProps) {
  const {
    sendNotificationEmail,
    setSendNotificationEmail,
    noReimbursements,
    setNoReimbursements,
    formValid,
    importing,
    importError,
    importedAuditId,
    auditDate,
    setAuditDate,
    nextAuditDate,
    setNextAuditDate,
    auditFileError,
    handleFileChange,
    importUpdate,
  } = useImportAuditToCompanyForm(companyId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await importUpdate(companyName);
    if (!importError) {
      onImported();
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Toolbar>
        <h1>Import an Audit</h1>
      </Toolbar>
      <form onSubmit={handleSubmit}>
        <div className="p-5 border-b">
          {importError && (
            <div className="mb-4">
              <ErrorMessage message={importError.message} />
            </div>
          )}
          {importedAuditId && (
            <div className="mb-4">
              <SuccessMessage
                message={`Successfully imported audit: ${importedAuditId}`}
              />
            </div>
          )}
          <h2 className="font-bold">Upload Audit File</h2>
          <p className="text-sm text-gray-600 mb-5">
            Select the audit update file from your computer. This file must be
            in Excel (.xlsx) format.
          </p>
          <input
            disabled={noReimbursements}
            type="file"
            onChange={handleFileChange}
          />
          <p>
            <label>
              <input
                type="checkbox"
                className="mt-5 mr-2"
                checked={noReimbursements}
                onChange={(e) => {
                  setNoReimbursements(e.target.checked);
                }}
              />
              No Reimbursements
            </label>
          </p>

          {auditFileError && (
            <div className="mt-5">
              <ErrorMessage message={auditFileError.message} />
            </div>
          )}
        </div>
        <div className="p-5 border-b">
          <h2 className="font-bold">Next Audit Date</h2>
          <p className="text-sm text-gray-600 mb-5">
            Choose the date the customer will be shown for their next audit. By
            default, this is 3 months in the future.
          </p>
          <input
            type="date"
            className="bg-gray-100 w-full p-3"
            value={nextAuditDate}
            onChange={(e) => setNextAuditDate(e.target.value)}
          />
        </div>

        <div className="p-5 border-b">
          <h2 className="font-bold">Audit Creation Date</h2>
          <p className="text-sm text-gray-600 mb-5">
            Choose the date this audit was created if you would like it to be
            something other than today's date.
          </p>
          <input
            type="date"
            className="bg-gray-100 w-full p-3"
            value={auditDate}
            onChange={(e) => setAuditDate(e.target.value)}
          />
          <label>
            <input
              type="checkbox"
              className="mt-5 mr-2"
              checked={sendNotificationEmail}
              onChange={(e) => setSendNotificationEmail(e.target.checked)}
            />
            Send notification email
          </label>
        </div>

        <div className="p-5 flex items-center">
          <Button
            type="submit"
            disabled={(!formValid || importing) && !noReimbursements}
            primary
            className="mr-4"
          >
            {importing ? "Importing..." : "Import Audit"}
          </Button>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className=""
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
