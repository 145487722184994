import React from "react";
import { Audit } from "../../../types/ApiTypes";
import styles from "./AuditList.module.css";
import firebase from "firebase/app";
import { AuditListItem } from "../AuditListItem/AuditListItem";

export type AuditListProps = {
  audits: firebase.firestore.DocumentSnapshot<Audit>[];
  showHeader?: boolean;
  showViewDetails?: boolean;
  showAmount?: boolean;
};

export function AuditList({
  audits,
  showHeader = true,
  showViewDetails = true,
  showAmount = true,
}: AuditListProps) {
  return (
    <div className="w-full">
      {showHeader && (
        <div className={styles.auditListHeader}>
          <p className={styles.auditStatus}>Audit Status</p>
          <p className={styles.date}>Date</p>
          {showAmount && (
            <p className={styles.totalRecovered}>Total Recovered</p>
          )}
          {!showAmount && <p className={styles.totalRecovered}>&nbsp;</p>}
        </div>
      )}

      {audits.map((audit, index) => (
        <AuditListItem
          key={audit.id}
          audit={audit}
          last={index === audits.length - 1}
          showViewDetails={showViewDetails}
          showAmount={showAmount}
        />
      ))}
    </div>
  );
}
