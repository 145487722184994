import { Button } from "../common/Button/Button";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { User as UserIcon, Grid, Settings, UserPlus } from "react-feather";
import React, { useEffect, useState } from "react";
import { AddBothUserCompanyModal } from "./user/UserForms/AddBothUserCompanyModal";

export type SidebarProps = {
  searchElement?: React.ReactNode;
  isUser?: boolean;
  children?: React.ReactNode;
};

export function Sidebar({ searchElement, isUser, children }: SidebarProps) {
  const match = useRouteMatch();
  const history = useHistory();
  const [addUserCompanyModal, setAddUserCompanyModal] = useState(false);

  return (
    <>
      <span className=" bg-white border-1 border-solid border-[#dedede] flex h-full items-stretch">
        <div className=" p-1 w-14 border-r border-solid border-[#dedede] flex flex-col h-full justify-between items-center">
          <span className="flex flex-col items-center space-y-1">
            <Button
              active={match.path.startsWith("/admin/company")}
              icon
              clear
              onClick={() => history.push("/admin/company")}
            >
              <img color="#27245B" src="/company.svg" alt="" />
            </Button>
            <Button
              active={match.path.startsWith("/admin/user")}
              onClick={() => history.push("/admin/user")}
              icon
              clear
            >
              <UserIcon color="#27245B" size={26}></UserIcon>
            </Button>
          </span>
          <span className="flex flex-col items-center ">
            <Button icon clear>
              <UserPlus
                onClick={() => {
                  setAddUserCompanyModal(true);
                }}
                color="#27245B"
                size={26}
              />
            </Button>
            {addUserCompanyModal && (
              <AddBothUserCompanyModal
                isOpen={true}
                onClose={() => {
                  setAddUserCompanyModal(false);
                }}
              />
            )}
            <Button
              onClick={() => history.push("/admin/quickbooks")}
              icon
              clear
            >
              <Settings color="#27245B" size={26} />
            </Button>
          </span>
        </div>
        <div className="overflow-auto border-r border-solid border-[#dedede] flex flex-col h-full justify-between items-center">
          <div className="bg-gray-50 w-80 flex flex-col ">
            <div className="flex bg-white flex-col justify-center h-10">
              <span className="pl-2  font-semibold m-0 text-sniper-purple">
                {isUser ? "Users" : "Companies"}
              </span>
            </div>
            {!!searchElement && <div className="bg-white">{searchElement}</div>}
            {children}
          </div>
        </div>
      </span>
    </>
  );
}
