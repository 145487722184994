import { useEffect, useState } from "react";
import firebase from "firebase/app";
import dayjs from "dayjs";

export function useLoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const login = async () => {
    setLoading(true);
    setError(undefined);

    try {
      const loginFunc = firebase.functions().httpsCallable("login");

      const result = await loginFunc({
        email,
        password,
      });

      if (result.data.accepted === true) {
        await firebase.auth().signInWithCustomToken(result.data.token);

        setLoading(false);

        return true;
      } else {
        console.log(`Login unsuccessful for reason '${result.data.reason}'.`);
        if (result.data.reason == "wrong_email")
          throw new Error("There is no user with that email in the system.");
        else if (result.data.reason == "wrong_password")
          throw new Error("That password is incorrect.");
        else
          throw new Error(
            "Unable to login due to network error, please try again later, and contact us if the error persists."
          );
      }
    } catch (err) {
      setPassword("");
      setError(err);
    } finally {
      setLoading(false);
    }

    return false;
  };

  useEffect(() => {
    setFormValid(email !== "" && password !== "");
  }, [email, password]);

  return {
    email,
    setEmail,
    password,
    setPassword,
    error,
    loading,
    formValid,
    login,
  };
}
