import React from "react";
import { Helmet } from "react-helmet";
import { Navbar } from "../components/common/Navbar/Navbar";
import { Content } from "../components/common/Content/Content";
import { Toolbar } from "../components/common/Toolbar/Toolbar";
import { Container } from "../components/common/Container/Container";
import { AccountSettings } from "../components/account/AccountSettings/AccountSettings";
import { MostRecentAudit } from "../components/account/MostRecentAudit/MostRecentAudit";
import styles from "../styles/Account.module.css";
import { useInvoices } from "../hooks/useInvoices";
import { Loader } from "react-feather";
import { ErrorMessage } from "../components/common/Messages/ErrorMessage";
import { InvoiceList } from "../components/account/InvoiceList/InvoiceList";

export default function Account() {
  const { invoices, loading, error } = useInvoices();

  return (
    <>
      <Helmet>
        <title>Account - Refund Sniper</title>
      </Helmet>

      <Navbar admin={false} />

      <Container>
        <div className="flex flex-col md:flex-row">
          <Content className={styles.accountSettings}>
            <Toolbar>
              <h1>Account Settings</h1>
            </Toolbar>
            <div>
              <AccountSettings />
            </div>
          </Content>
          <div className={styles.auditsAndInvoices}>
            <Content className="mb-4 h-[503px]">
              <Toolbar>
                <h1>Most Recent Audit</h1>
              </Toolbar>
              <div style={{paddingBottom: "376px"}} className=" TEst flex justify-center pt-5 pr-5 pl-5">
                <MostRecentAudit />
              </div>
            </Content>
            {/* <Content className="flex-1">
              <Toolbar>
                <h1>Invoices</h1>
              </Toolbar>
              <div className="p-5 max-h-96 overflow-y-auto">
                {loading && (
                  <div className="flex items-center justify-center">
                    <Loader className="animate-spin" />
                  </div>
                )}

                {!loading && error && <ErrorMessage message={error.message} />}

                {!loading && !error && invoices.length > 0 && (
                  <InvoiceList invoices={invoices} />
                )}

                {!loading && !error && invoices.length === 0 && (
                  <div className="bg-gray-100 rounded p-5 text-center">
                    <p className="m-0">
                      You don't have any invoices yet. Please check back later!
                    </p>
                  </div>
                )}
              </div>
            </Content> */}
          </div>
        </div>
      </Container>
    </>
  );
}
