import { Helmet } from "react-helmet";
import { Container } from "../../components/common/Container/Container";
import { Content } from "../../components/common/Content/Content";
import { Toolbar } from "../../components/common/Toolbar/Toolbar";
import { useDocument } from "react-firebase-hooks/firestore";
import { QuickBooksData } from "../../types/ApiTypes";
import firebase from "firebase/app";
import { Loader } from "react-feather";
import dayjs from "dayjs";
import { Button } from "../../components/common/Button/Button";
import { useState } from "react";

export function QuickBooksDisconnected() {
  const [loading, setLoading] = useState(false);

  const reconnect = async () => {
    setLoading(true);
    const getQuickbooksLoginUri = firebase
      .functions()
      .httpsCallable("getQuickbooksLoginUri");
    const result = await getQuickbooksLoginUri({});
    window.open(result.data.authUri);
  };

  return (
    <div className="p-5">
      <div className="p-5 mb-5 bg-red-100 rounded-md">
        <strong>QuickBooks is not connected</strong>
        <p className="text-sm">
          Customers will not be able to view their invoices until you sign in
          with QuickBooks.
        </p>
      </div>
      <div className="flex">
        <Button className="mr-2" onClick={reconnect} disabled={loading} primary>
          {loading ? "Please Wait..." : "Connect QuickBooks"}
        </Button>
      </div>
    </div>
  );
}

export function QuickBooksConnectionStatus({ data }: { data: QuickBooksData }) {
  const refreshTokenExpiresAt = dayjs.unix(data.refreshTokenExpiresAt.seconds);

  const reconnect = async () => {
    const getQuickbooksLoginUri = firebase
      .functions()
      .httpsCallable("getQuickbooksLoginUri");
    const result = await getQuickbooksLoginUri({});
    window.open(result.data.authUri);
  };

  const disconnect = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to disconnect QuickBooks access? Customers won't be able to view their invoices until you log back in."
    );
    if (!confirmation) return;
    await firebase.firestore().collection("admin").doc("quickbooks").delete();
  };

  return (
    <div className="p-5">
      <div className="p-5 mb-5 bg-green-100 rounded-md">
        <strong>QuickBooks is connected</strong>
        <p>
          Access can be automatically renewed until{" "}
          {refreshTokenExpiresAt.format("MMMM D, YYYY")}
        </p>
        <p className="text-sm text-gray-500">
          After this, you will need to sign back into QuickBooks to allow
          customers to view and pay invoices.
        </p>
      </div>
      <div className="flex">
        <Button className="mr-2" onClick={reconnect} primary>
          Reconnect QuickBooks
        </Button>
        <Button onClick={disconnect}>Disconnect</Button>
      </div>
    </div>
  );
}

export default function QuickBooks() {
  const [quickbooks, loading] = useDocument<QuickBooksData>(
    firebase.firestore().collection("admin").doc("quickbooks")
  );

  return (
    <>
      <Helmet>
        <title>Connect QuickBooks - Refund Sniper</title>
      </Helmet>
      <Container>
        <Content>
          <Toolbar>
            <h1>Connect QuickBooks</h1>
          </Toolbar>

          {loading && (
            <div className="p-4 flex items-center justify-center">
              <Loader className="animate-spin" />
            </div>
          )}

          {!loading &&
            quickbooks.exists &&
            quickbooks.data().connected === true && (
              <QuickBooksConnectionStatus data={quickbooks.data()} />
            )}

          {!loading &&
            (!quickbooks.exists || quickbooks.data().connected === false) && (
              <QuickBooksDisconnected />
            )}
        </Content>
      </Container>
    </>
  );
}
