import { CheckCircle, Circle, Loader } from "react-feather";
import { useCompanies } from "../../../../hooks/useCompanies";
import { useCallback, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useCustomerCompanyList } from "../../../../hooks/useCustomerCompanyList";
import _ from "lodash";

export type SearchCompaniesProps = {
  userId: string;
  selectedCompany: string;
  setSelectedCompany?: (value: string) => void;
};

export default function SearchCompanies({
  userId,
  selectedCompany,
  setSelectedCompany,
}: SearchCompaniesProps) {
  const [eligibleCompanies, setEligibleCompanies] = useState([]);
  const { companies, loading: companiesLoading } = useCompanies();
  const { userCompanies, loading: userCompaniesLoading } =
    useCustomerCompanyList(userId);

  const [search, setSearch] = useState("");

  useEffect(() => {
    const allCompanies = [];
    const filterCompanies = [];

    if (!(companiesLoading || userCompaniesLoading)) {
      companies.docs.map((c) => {
        allCompanies.push({ id: c.id, data: c.data() });
      });
      userCompanies.docs.map((c) => {
        filterCompanies.push({ id: c.id, data: c.data() });
      });
      const filteredCompanies = _.differenceWith(
        allCompanies,
        filterCompanies,
        (o1, o2) => {
          return o1.id === o2.data.company.id;
        }
      );
      setEligibleCompanies([...filteredCompanies]);
    }
  }, [companiesLoading, userCompaniesLoading]);

  const getFilteredCompanies = useCallback(() => {
    const searchedCompanies = [];
    const normalizedSearch = search.toLowerCase().trim();

    if (normalizedSearch.length === 0) {
      return eligibleCompanies;
    } else {
      eligibleCompanies.filter((c) => {
        const normalizedName = c.data.name.toLowerCase().trim();

        if (normalizedName.includes(normalizedSearch)) {
          searchedCompanies.push({ id: c.id, data: c.data });
        }
      });
      return searchedCompanies;
    }
  }, [search, companies, eligibleCompanies]);

  return (
    <>
      <div className="bg-white border border-solid border-[#dedede] h-full ">
        <div className="border p-2 border-b flex">
          <input
            type="search"
            className="w-full bg-gray-100 p-2"
            placeholder="Search for Companies..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <RadioGroup
          className="overflow-y-auto h-80"
          value={selectedCompany}
          onChange={setSelectedCompany}
        >
          {companiesLoading && (
            <div className="flex items-center justify-center p-4">
              <Loader className="animate-spin" />
            </div>
          )}
          {!companiesLoading &&
            getFilteredCompanies().map((company) => (
              <RadioGroup.Option
                key={company.id}
                value={company.id}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring ring-inherit border-solid border-[#3b82f6] "
                      : ""
                  }
                                ${
                                  checked
                                    ? "border border-solid border-[#3b82f6] bg-opacity-75 text-sniper-purple"
                                    : "bg-gray"
                                }
                                relative border border-solid border-[#dedede] px-5 py-4 cursor-pointer flex focus:outline-none `
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center w-full">
                      {checked && (
                        <div className="flex-shrink-0 text-sniper-puple pr-3">
                          <CheckCircle className="w-6 h-6" />
                        </div>
                      )}
                      {!checked && (
                        <div className="flex-shrink-0 text-sniper-puple pr-3">
                          <Circle className="w-6 h-6" />
                        </div>
                      )}
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-sniper-purple" : "text-gray-900"
                            }`}
                          >
                            {company.data.name}
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
        </RadioGroup>
      </div>
    </>
  );
}
