import React, { useEffect, useState } from "react";
import { useCustomerCompanyList } from "../../../hooks/useCustomerCompanyList";
import { ListItem } from "../ListItem";
import { Button } from "../../common/Button/Button";
import { CheckCircle, Plus } from "react-feather";
import { AddCompanyToUserModal } from "../company/CompanyForms/AddCompanyToUserModal";
import { AddCompanyModal } from "../company/CompanyForms/AddCompany";
import { Loader } from "react-feather";
import { ErrorMessage } from "../../common/Messages/ErrorMessage";
import { Transition } from "@headlessui/react";

export type UserCompanyListProps = {
  userId: string;
  readOnly?: boolean;
};

export function UserCompanyList({
  userId,
  readOnly = false,
}: UserCompanyListProps) {
  const { userCompanies, removeMembership, removing, removeError, loading } =
    useCustomerCompanyList(userId);

  const [userToCompanyModal, setUserToCompanyModal] = useState(false);
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  function showSavedChangesToast() {
    setChangesSaved(true);
    setTimeout(() => setChangesSaved(false), 5000);
  }

  return (
    <>
      {addCompanyModal && (
        <AddCompanyModal
          userId={userId}
          isOpen={true}
          onSubmit={() => showSavedChangesToast()}
          onClose={() => setAddCompanyModal(false)}
        />
      )}

      {userToCompanyModal && (
        <AddCompanyToUserModal
          userId={userId}
          isOpen={userToCompanyModal}
          onSubmit={() => showSavedChangesToast()}
          onClose={() => setUserToCompanyModal(false)}
        />
      )}

      <div className="flex flex-col border rounded relative">
        <div className="flex-1 max-h-60 overflow-y-auto divide-y">
          {removeError && (
            <div className="mb-4">
              <ErrorMessage message={removeError.message} />
            </div>
          )}
          {loading && (
            <div className="flex items-center justify-center p-20">
              <Loader className="animate-spin" />
            </div>
          )}
          {!loading && userCompanies.docs.length === 0 && (
            <div className="p-5">
              No Companies
              {/* <NonIdealState icon="user" title="No Contacts" /> */}
            </div>
          )}
          {!loading &&
            userCompanies.docs.length > 0 &&
            userCompanies.docs.map((c) => (
              <ListItem
                page="company"
                key={c.data().company.id}
                assetId={c.data().company.id}
                name={c.data().companyName}
                onRemove={removeMembership}
                removing={removing}
                readOnly={readOnly}
              />
            ))}
        </div>

        <div className="pl-2 p-2 flex border-t items-center justify-between  bg-gray-50">
          <div className="flex items-center space-x-2">
            <Button
              className="text-gray"
              onClick={() => {
                setAddCompanyModal(true);
              }}
              icon
              xsmall
              secondary
            >
              Add New Company
            </Button>

            <Button
              className="text-gray"
              onClick={() => {
                setUserToCompanyModal(true);
              }}
              icon
              xsmall
              secondary
            >
              Add Existing Company
            </Button>
          </div>
          <Transition
            show={changesSaved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              style={{ height: 32 }}
              className="flex items-center space-x-2 px-2 bg-green-100 ring-2 ring-green-200 rounded text-green-800"
            >
              <CheckCircle />
              <p className="font-medium text-sm">Company added to user!</p>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
