import React, { useContext } from "react";
import styles from "./Navbar.module.css";
import { Link, NavLink, useHistory } from "react-router-dom";
import { CheckCircle, HelpCircle, Home, LogOut, User } from "react-feather";
import firebase from "firebase/app";
import { CurrentUserContext } from "../../../context/CurrentUserContext";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { CompanyMembership } from "../../../types/ApiTypes";

export function ActiveCompanyDropdown() {
  const [user] = useAuthState(firebase.auth());

  const { activeCompanyId, setActiveCompanyId } =
    useContext(CurrentUserContext);

  const [companies, loading, error] = useCollection<CompanyMembership>(
    firebase
      .firestore()
      .collectionGroup("memberships")
      .where(
        "user",
        "==",
        firebase.firestore().collection("users").doc(user.uid)
      )
  );

  return (
    <div className="mr-4 w-48">
      {loading && (
        <div className="w-full h-9 bg-gray-100 animate-pulse rounded" />
      )}
      {!loading && (
        <select
          value={activeCompanyId}
          onChange={(e) => setActiveCompanyId(e.target.value)}
          className="w-full h-9 bg-gray-100 p-2 rounded hover:bg-gray-200 cursor-pointer"
        >
          <option value="" disabled>
            Select a company
          </option>

          {companies.docs.map((o) => (
            <option key={o.id} value={o.data().company.id}>
              {o.data().companyName}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

interface NavbarPropTypes {
  admin: boolean;
}

export function Navbar(props: NavbarPropTypes) {
  const history = useHistory();

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .finally(() => history.replace("/login"));
  };

  return (
    <header className={styles.navbar}>
      <Link to="/">
        <img src="/refund_sniper@2x.png" className={styles.brand} alt="" />
      </Link>

      {!props.admin ? (
        <>
          <nav>
            <ul>
              <li>
                <NavLink to="/" exact>
                  <Home />
                  <span>Home</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/audits">
                  <CheckCircle />
                  <span>Audits</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/account">
                  <User />
                  <span>Account</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact">
                  <HelpCircle />
                  <span>Contact</span>
                </NavLink>
              </li>
            </ul>
          </nav>

          <div className="flex items-center">
            <ActiveCompanyDropdown />
            <a href="#" onClick={logout}>
              <LogOut color="#27245B" />
            </a>
          </div>
        </>
      ) : (
        <div className="flex items-center">
          <a href="#" onClick={logout}>
            <LogOut color="#27245B" />
          </a>
        </div>
      )}
    </header>
  );
}
