import React from "react";
import styles from "./Toolbar.module.css";
import classNames from "classnames";

export type ToolbarProps = {
  children?: React.ReactNode | React.ReactNode[];
  hasButtons?: boolean;
};

export function Toolbar({ children, hasButtons }: ToolbarProps) {
  return (
    <header
      className={classNames(styles.toolbar, {
        [styles.withButtons]: hasButtons,
      })}
    >
      {children}
    </header>
  );
}
