import React, { useEffect, useState } from "react";
import { useCompanyUserList } from "../../../../hooks/useCompanyUserList";
import { ListItem } from "../../ListItem";
import { Button } from "../../../common/Button/Button";
import { CheckCircle, Loader, Plus } from "react-feather";
import { AddUserToCompanyModal } from "../../user/UserForms/AddUserToCompanyModal";
import { AddUserModal } from "../../user/UserForms/AddUserModal";
import { Transition } from "@headlessui/react";

export type CompanyUserListProps = {
  companyId: string;
  readOnly?: boolean;
};

export function CompanyUserList({
  companyId,
  readOnly = false,
}: CompanyUserListProps) {
  const { companyUsers, removeMembership, removing, loading } =
    useCompanyUserList(companyId);
  const [addUserModal, setAddUserModal] = useState(false);
  const [userToCompanyModal, setUserToCompanyModal] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  function showSavedChangesToast() {
    setChangesSaved(true);
    setTimeout(() => setChangesSaved(false), 5000);
  }

  return (
    <>
      {userToCompanyModal && (
        <AddUserToCompanyModal
          companyId={companyId}
          isOpen={true}
          onSubmit={() => showSavedChangesToast()}
          onClose={() => setUserToCompanyModal(false)}
        />
      )}
      {addUserModal && (
        <AddUserModal
          companyId={companyId}
          isOpen={addUserModal}
          onSubmit={() => showSavedChangesToast()}
          onClose={() => setAddUserModal(false)}
        />
      )}
      <div className="flex flex-col border rounded relative">
        <div className="flex-1 max-h-60 overflow-y-auto divide-y">
          {loading && (
            <div className="flex items-center justify-center p-20">
              <Loader className="animate-spin" />
            </div>
          )}
          {!loading && companyUsers?.docs.length === 0 && (
            <div className="p-5">No Users</div>
          )}
          {!loading &&
            companyUsers?.docs.length > 0 &&
            companyUsers?.docs.map((c) => (
              <ListItem
                page="user"
                key={c.data().user.id}
                email={c.data().userEmail}
                assetId={c.data().user.id}
                name={c.data().userName}
                onRemove={removeMembership}
                removing={removing}
                readOnly={readOnly}
              />
            ))}
        </div>

        <div className="pl-2 p-2 flex border-t items-center justify-between  bg-gray-50">
          <div className="flex items-center space-x-2">
            <Button
              className="text-gray"
              onClick={() => {
                setAddUserModal(true);
              }}
              icon
              xsmall
              secondary
            >
              Add New User
            </Button>

            <Button
              className="text-gray"
              onClick={() => {
                setUserToCompanyModal(true);
              }}
              icon
              xsmall
              secondary
            >
              Add Existing User
            </Button>
          </div>
          <Transition
            show={changesSaved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              style={{ height: 32 }}
              className="flex items-center space-x-2 px-2 bg-green-100 ring-2 ring-green-200 rounded text-green-800"
            >
              <CheckCircle />
              <p className="font-medium text-sm">User added to company!</p>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
