import { Helmet } from "react-helmet";
import { Container } from "../../../components/common/Container/Container";
import { Button } from "../../../components/common/Button/Button";
import { Redirect, useHistory } from "react-router-dom";
import { Navbar } from "../../../components/common/Navbar/Navbar";
import { Sidebar } from "../../../components/admin/Sidebar";
import { Plus } from "react-feather";
import { useCompanies } from "../../../hooks/useCompanies";
import { Company } from "../../../types/ApiTypes";
import firebase from "firebase/app";
import { SidebarItem } from "../../../components/admin/SidebarItem";
import { useCallback, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { CompanyDetails } from "./[companyId]";
import { AddBothUserCompanyModal } from "../../../components/admin/user/UserForms/AddBothUserCompanyModal";

export default function CompanyAdmin() {
  const history = useHistory();

  const {
    companies,
    loading: customersLoading,
    error: customersError,
  } = useCompanies();
  const [search, setSearch] = useState("");
  const [addUserCompanyModal, setAddUserCompanyModal] = useState(false);

  const getFilteredCompanies = useCallback(() => {
    const allCompanies = [];
    const normalizedSearch = search.toLowerCase().trim();
    if (normalizedSearch.length === 0) {
      companies.docs.map((c) => {
        allCompanies.push({ id: c.id, data: c.data() });
      });
    } else {
      companies.docs.filter((c) => {
        const normalizedName = c.data().name.toLowerCase().trim();
        if (normalizedName.includes(normalizedSearch)) {
          allCompanies.push({ id: c.id, data: c.data() });
        }
      });
    }
    return allCompanies;
  }, [search, companies]);

  return (
    <>
      <Helmet>
        <title>Admin - Refund Sniper</title>
      </Helmet>

      <Navbar admin={true} />
      <Container admin={true}>
        <div className="bg-white border-2 border-solid border-[#dedede] flex h-full items-stretch">
          <Sidebar
            isUser={false}
            searchElement={
              <>
                <div className="border-t border-b w-full p-2  flex">
                  <input
                    type="search"
                    className="w-full bg-gray-100 p-2 rounded-md"
                    placeholder="Search for companies..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </>
            }
          >
            {!customersLoading &&
              getFilteredCompanies().map((companies) => (
                <SidebarItem
                  page="company"
                  key={companies.id}
                  title={companies.data.name}
                  href={`/admin/company/${companies.id}`}
                />
              ))}
          </Sidebar>
          <div className="flex-1 items-center overflow-y-auto">
            <Route
              path="/admin/company/:companyId"
              component={CompanyDetails}
            />
            <Route exact path="/admin">
              <Redirect to="/admin/company" />
            </Route>
            <Route
              path="/admin/company"
              exact
              render={(): JSX.Element => (
                <div className="flex flex-col h-full space-y-4 items-center justify-center">
                  <img style={{ width: "5rem" }} src="/company.svg" alt="" />
                  <div> No Company Selected </div>
                  <Button
                    onClick={() => setAddUserCompanyModal(true)}
                    secondary
                    className="w-auto h-8"
                  >
                    Add User and Company
                  </Button>
                  {addUserCompanyModal && (
                    <AddBothUserCompanyModal
                      isOpen={true}
                      onClose={() => setAddUserCompanyModal(false)}
                    />
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </Container>
    </>
  );
}
