import React, { PropsWithChildren, useState } from "react";

export const CurrentUserContext = React.createContext<{
  activeCompanyId: string;
  setActiveCompanyId: (newId: string) => void;
}>({
  activeCompanyId: "",
  setActiveCompanyId: () => {},
});

export function CurrentUserContextProvider({
  defaultCompanyId,
  children,
}: PropsWithChildren<{ defaultCompanyId: string }>) {
  const [activeCompanyId, setActiveCompanyId] = useState<string>(
    window.localStorage.getItem("activeCompanyId") ?? defaultCompanyId
  );

  const setAndSaveActiveCompanyId = (newId: string) => {
    setActiveCompanyId(newId);
    window.localStorage.setItem("activeCompanyId", newId);
  };

  return (
    <CurrentUserContext.Provider
      value={{
        activeCompanyId,
        setActiveCompanyId: setAndSaveActiveCompanyId,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}
