import { Helmet } from "react-helmet";
import { Container } from "../../../components/common/Container/Container";
import { Button } from "../../../components/common/Button/Button";
import { useHistory } from "react-router-dom";
import { Navbar } from "../../../components/common/Navbar/Navbar";
import { Sidebar } from "../../../components/admin/Sidebar";
import { useCustomers } from "../../../hooks/useCustomers";
import { SidebarItem } from "../../../components/admin/SidebarItem";
import { useCallback, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { UserDetails } from "./[userId]";
import { User } from "react-feather";
import { AddBothUserCompanyModal } from "../../../components/admin/user/UserForms/AddBothUserCompanyModal";

export default function UserAdmin() {
  const history = useHistory();

  const {
    customers,
    loading: customersLoading,
    error: customersError,
  } = useCustomers();
  const [search, setSearch] = useState("");
  const [addUserCompanyModal, setAddUserCompanyModal] = useState(false);

  const getFilteredCustomers = useCallback(() => {
    const allCustomers = [];
    const normalizedSearch = search.toLowerCase().trim();
    if (normalizedSearch.length === 0) {
      customers.docs.map((c) => {
        if (c.data().admin !== true) {
          allCustomers.push({ id: c.id, data: c.data() });
        }
      });
    } else {
      customers.docs.filter((c) => {
        if (c.data().admin !== true) {
          const normalizedName = c.data().name.toLowerCase().trim();
          const normalizedEmail = c.data().email.toLowerCase().trim();

          if (
            normalizedName.includes(normalizedSearch) ||
            normalizedEmail.includes(normalizedSearch)
          ) {
            allCustomers.push({ id: c.id, data: c.data() });
          }
        }
      });
    }
    return allCustomers;
  }, [search, customers]);

  return (
    <>
      <Helmet>
        <title>Admin - Refund Sniper</title>
      </Helmet>

      <Navbar admin={true} />
      <Container admin={true}>
        <div className="bg-white border-2 border-solid border-[#dedede] flex h-full items-stretch">
          <Sidebar
            isUser={true}
            searchElement={
              <>
                <div className="border-t border-b w-full p-2  flex">
                  <input
                    type="search"
                    className="w-full bg-gray-100 p-2 rounded-md"
                    placeholder="Search for users..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </>
            }
          >
            {!customersLoading &&
              getFilteredCustomers().map((customer) => (
                <SidebarItem
                  page="user"
                  key={customer.id}
                  title={customer.data.name}
                  subtitle={customer.data.email}
                  href={`/admin/user/${customer.id}`}
                />
              ))}
          </Sidebar>
          <div className="flex-1">
            <Route path="/admin/user/:userId" component={UserDetails} />
            <Route
              path="/admin/user"
              exact
              render={(): JSX.Element => (
                <div className="flex flex-col h-full space-y-4 items-center justify-center">
                  <User color="#27245B" size={75} />
                  <div> No User Selected </div>
                  <Button
                    onClick={() => setAddUserCompanyModal(true)}
                    secondary
                    className="h-8"
                  >
                    Add User and Company
                  </Button>
                  {addUserCompanyModal && (
                    <AddBothUserCompanyModal
                      isOpen={true}
                      onClose={() => setAddUserCompanyModal(false)}
                    />
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </Container>
    </>
  );
}
