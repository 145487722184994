import React from "react";
import { Helmet } from "react-helmet";
import { Navbar } from "../../components/common/Navbar/Navbar";
import { Container } from "../../components/common/Container/Container";
import { Content } from "../../components/common/Content/Content";
import { Toolbar } from "../../components/common/Toolbar/Toolbar";
import { useAudits } from "../../hooks/useAudits";
import { AuditList } from "../../components/audits/AuditList/AuditList";
import { Loader } from "react-feather";
import { AuditCountdown } from "../../components/common/AuditCountdown/AuditCountdown";

export default function Audits() {
  const { audits, loading, error } = useAudits();

  const mostRecentAudit = !loading && audits.docs[0];
  const previousAudits = !loading && audits.docs.slice(1, audits.docs.length);

  return (
    <>
      <Helmet>
        <title>Audits - Refund Sniper</title>
      </Helmet>

      <Navbar admin={false} />

      <Container>
        <>
          <Content className="mb-20">
            <Toolbar>
              <h1>Most Recent Audit</h1>
            </Toolbar>
            <div className="p-4">
              {loading && (
                <div className="flex items-center justify-center py-3">
                  <Loader className="animate-spin" />
                </div>
              )}
              {!loading && mostRecentAudit && (
                <AuditList audits={[mostRecentAudit]} />
              )}
              {!loading && !mostRecentAudit && (
                <div className="flex items-center justify-center py-10">
                  <AuditCountdown />
                </div>
              )}
            </div>
          </Content>

          {!loading && previousAudits.length > 0 && (
            <Content className="mb-16">
              <Toolbar>
                <h1>Previous Audits</h1>
              </Toolbar>
              <div className="p-4">
                <AuditList audits={previousAudits} />
              </div>
            </Content>
          )}
        </>
      </Container>
    </>
  );
}
