import React from "react";
import classNames from "classnames";
import styles from "./Button.module.css";

export type ButtonProps = {
  children?: React.ReactNode | React.ReactNode[];
  small?: boolean;
  xsmall?: boolean;
  primary?: boolean;
  secondary?: boolean;
  clear?: boolean;
  fill?: boolean;
  active?: boolean;
  type?: "button" | "submit" | "reset";
  icon?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

export function Button({
  children,
  type = "button",
  disabled = false,
  small = false,
  xsmall = false,
  primary = false,
  secondary = false,
  clear = false,
  fill = false,
  icon = false,
  active = false,
  className,
  onClick,
}: ButtonProps) {
  return (
    <button
      className={classNames(
        className,
        styles.button,
        { [styles.small]: small },
        { [styles.xsmall]: xsmall },
        { [styles.primary]: primary },
        { [styles.clear]: clear },
        { [styles.active]: active },
        { [styles.secondary]: secondary },
        { [styles.fill]: fill },
        { [styles.icon]: icon }
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
